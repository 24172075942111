@media (min-width: 768px) {
    .form-horizontal .form-group div:nth-child(2) {
        margin-bottom: 10px;
    }

    .form-horizontal .form-group div:last-child {
        margin-bottom: 0px;
    }
}

@media (min-width: 992px) {
    .form-horizontal .form-group div:nth-child(2) {
        margin-bottom: 0px;
    }
}

.input-group-addon {
    cursor: pointer;
}

.input-group-addon-small {
    padding: 5px 12px;
}

.form-horizontal label {
    font-weight: normal;
}

.bg-highlight {
    color: #767676;
    background-color: #f3f3f3;
}

.bg-lightblue {
    color: #fff;
    background-color: #063464;
}

.bg-blue {
    color: #fff;
    background-color: #074474;
}

fieldset.fieldset {
    padding: .35em .625em .75em;
    margin: 0 2px;
    border: 1px solid silver;
}

fieldset.fieldset legend {
    width: auto;
    border-bottom: none;
    margin-bottom: unset;
    font-size: unset;
}

.panel-blue {
    border-color: #337ab7;
}

.panel-blue>.panel-heading {
    color: #fff;
    background-color: #063464;
    border-color: #337ab7;
}