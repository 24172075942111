.inlumon-loader {
    display:table-cell;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0px;
    left: 0px;
    text-align:center;
    color:white;
    z-index: 1300;
}
.inlumon-loader.inlumon-loader-fixed {
    position: fixed !important;
    height: 100vh !important;
    width: 100vw !important;
}
.inlumon-loader > div {
    position:absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
}
.inlumon-loader i {
    color: white !important;
}
.inlumon-loader p {
    display: block !important;
}
ul.nav-mainlinks li ul {
    z-index: 1401 !important;
}