body {
    margin: 0;
    font-family: sans-serif;
    padding: unset !important;
    font-size: 12px;
}

.uib-datepicker button.btn {
    color: black;
    border: solid 1px;
    border-color: rgb(204, 204, 204);
}


/*important*/

.bg-gary {
    background-color: #fafafa;
}

.text-left-imp {
    text-align: left !important;
}

.width-big {
    width: 512px !important;
}

.mt35 {
    margin-top: 35px;
}

.input-full {
    width: 100% !important;
}

.text-justify {
    text-align: justify;
}

.ListNumber {
    margin-left: 10px;
}

.ListNumber li {
    list-style: decimal;
    padding-top: 10px;
    padding-left: 5px;
}

.mt20 {
    margin-top: 20px;
}

.toolBarAddform {
    background-color: #fafafa;
    border: 1px solid #dedede;
    padding-top: 10px;
    border-radius: 5px 5px 0px 0px;
}


/*end*/

.width55 {
    width: 55px;
}

.width115 {
    width: 115px !important;
}

.company-big-input {
    width: 565px ! important;
}

.ml40 {
    margin-left: 40px !important;
}

.ml180 {
    margin-left: 180px !important;
}

.ml16 {
    margin-left: 16px;
}

.ml17 {
    margin-left: 17px;
}

.ml22 {
    margin-left: 22px;
}

.radiolist {
    display: inline-table;
    width: 100%;
}


/*firm date*/

.dis-content {
    display: contents;
}

.all-form .form-div .input-date {
    width: 160px !important;
}

.first-date-icon {
    float: right;
    margin-top: -30px;
    margin-right: 4px;
}

.all-date-icon {
    float: right;
    margin-top: 6px;
    margin-left: 12px;
}

.mb10 {
    margin-bottom: 10px;
}


/*end*/


/*****cash balancing******/

.cashupperdiv {
    background-color: rgb(250, 250, 250);
    margin-top: 37px;
    margin-left: 2%;
    border: 1px solid rgb(222, 222, 222);
    padding: 1%;
    margin-bottom: 10px;
    width: 97%;
}

.cashupperdiv table {
    width: 100%;
    margin-top: 10px;
}

.cashtopheadingdesign {
    position: absolute;
    margin-top: -33px;
    background-color: rgb(250, 250, 250);
    border: 1px solid #dedede;
    padding-left: 17px;
    padding-top: 3px;
    padding-right: 17px;
    padding-bottom: 3px;
}

.sahsmalltab {
    position: absolute;
    background-color: rgb(255, 255, 255);
    margin-left: 15px;
    margin-top: -29px;
    border: 1px solid #dedede;
    padding-left: 10px;
    padding-right: 10px;
}

.cashsubtable {
    border: 1px solid rgb(222, 222, 222);
    margin-right: 2px;
    margin-top: 25px;
    padding: 1% 13px;
    float: left;
    width: 33%;
    background-color: #fdfdfd;
    border-radius: 4px;
}

.blockheader {
    height: 30px;
    background-color: #E5EDF2;
    width: 99.1%;
    border: 1px solid rgb(221, 227, 236);
    border-radius: 2px;
    color: rgb(89, 89, 89);
    font-size: 15px;
    font-weight: bold;
    padding-left: 5px;
    margin: 10px auto;
}

.cashsubtable table tr td span {
    font-size: 11px;
    padding-right: 3px;
}

#dvSystem {
    display: none;
}


/*****End*********/

.pr-4 {
    padding-right: 4px;
}

.firm-user-pic {
    height: 18px;
    text-align: right;
    width: 100%;
    margin-top: -29px;
    padding-right: 10px;
}

.rounded-container-bottom {
    border: 1px solid #ccc;
    border-radius: 0px 0px 5px 5px;
    margin-bottom: 20px;
    position: relative;
    padding: 10px;
    width: 100%;
}

.renwa-sm-label {
    width: 125px !important;
}

.ml35 {
    margin-left: 35px;
}

.big-textarea {
    width: 678px;
    height: 47px;
}

.big-text {
    width: 175px !important;
}

.backoffice-board-Name {
    float: left;
    font-family: tahoma;
    font-size: 27px;
    font-weight: 600;
    margin: 35px 0px 0px auto;
    text-align: center;
    width: 580px;
    text-shadow: 0px 4px 3px #d6d6d6;
    letter-spacing: 1px;
}

#copyright p {
    text-align: center;
    margin-bottom: 0px;
}

.tab-content-div {
    border: 1px solid #dedede;
    margin: 21px;
    padding: 10px;
    margin-top: -8px !important;
}

.width200 {
    width: 200px !important;
}


/*usertype*/

.heading-usertype {
    font-size: 26px;
    color: #181920;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 0;
    line-height: 64px;
    text-align: center;
}

.user-type-anchor .box-landing1:hover {
    -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    transition: 0.5s;
}

.user-type-anchor .box-landing2:hover {
    -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    transition: 0.5s;
}

.m-t3p {
    margin-top: 3%;
}

.box-landing1 {
    width: 50%;
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    -moz-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    -o-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    -moz-osx-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    -ms-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    border: 1px solid #dcdcdc;
}

.box-landing2 {
    width: 50%;
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    -moz-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    -o-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    -moz-osx-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    -ms-box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    box-shadow: 0 2px 8px 0 rgba(35, 47, 53, .09);
    border: 1px solid #dcdcdc;
}

.box-landing1 h2 {
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 400;
    clear: both;
    color: #4a545a;
    font-size: 20px;
    text-align: center;
    word-spacing: 1px;
    letter-spacing: 1px;
    line-height: 36px;
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

.box-landing2 h2 {
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 400;
    clear: both;
    color: #4a545a;
    font-size: 20px;
    text-align: center;
    word-spacing: 1px;
    letter-spacing: 1px;
    line-height: 36px;
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

.panel-border {
    border: 1px solid #B8C9FF;
    border-radius: 5px;
    background-color: white;
}

.mb-30 {
    margin-bottom: 30px;
}


/*end*/

.text-right {
    /* text-align: right !important; */
}

.indi-info-textbox {
    width: 150px !important;
}

.indi-info-label {
    width: 119px !important;
}

.ml55 {
    margin-left: 55px;
}

.text-left {
    text-align: left;
}

.color-white {
    color: white;
}

.popstyle {
    position: fixed;
    z-index: 100;
    font-weight: bold;
    background-color: #074474;
    color: white;
    padding: 25px;
    text-align: center;
    transition: opacity 0.2s 0 ease;
    left: 30%;
    border-radius: 4px;
    box-shadow: 0px 0px 19px #bdbdbd;
    right: 30%;
    top: 5px;
}


/*applicant online*/

.innertextarea {
    width: 537px;
    float: right;
    margin-right: 8px;
}

.color-red {
    color: red;
}

.width99 {
    width: 99% !important;
}

.width100 {
    width: 100% !important;
}

.width50 {
    width: 50% !important;
}

.width400 {
    width: 400px !important;
}

.width77 {
    width: 77% !important;
}

.fee-type-online {
    width: 165px !important;
}

.width560 {
    width: 560px !important;
}

.width245 {
    width: 245px;
}


/*End*/

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
    display: inline-block;
    margin-top: 0 !important;
    position: static;
    margin-right: 4px;
    vertical-align: top;
}

.container {
    padding: 0;
}

.btn-success {
    /* background-image: linear-gradient(to bottom, #5cb85c 0px, #419641 100%); */
    /* background-repeat: repeat-x; */
    background-color: #214A21;
    /* cursor: pointer; */
}

.btn-blue-backoffice {
    /*     box-shadow: rgb(240, 247, 250) 0px 1px 0px 0px; */
    /*     background: linear-gradient(rgb(51, 189, 239) 5%, rgb(1, 154, 210) 100%) rgb(51, 189, 239); */
    background-color: rgb(1, 154, 210);
    border-radius: 4px;
    border: 1px solid rgb(5, 127, 208);
    display: inline-block;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-family: Arial;
    font-size: 15px;
    /*     font-weight: bold; */
    padding: 6px 24px;
    text-decoration: none;
    /*     text-shadow: rgb(91, 97, 120) 0px -1px 0px; */
}

.btn-blue-backoffice:hover {
    /*     background: linear-gradient(rgb(45, 164, 208) 5%, rgb(27, 179, 234) 100%) rgb(36, 159, 204); */
    background-color: rgb(27, 179, 234);
    color: white;
}

.btn-blue-backoffice.disable-button:hover {
    background: darkgrey;
}

.input-sm,
select.input-sm,
.input-width {
    height: inherit !important;
    padding: 3px 3px !important;
}

.inline-error {
    display: inline-block;
}

a.renewalbo-li-a:hover {
    color: #bbb;
    text-decoration: underline;
}

a {
    cursor: pointer;
}

h4,
h2 {
    font-size: 14px;
}

.pagination>li>a,
.pagination>li>span {
    padding: 4px 8px;
    border: 1px solid #000080;
    border-radius: 0 !important;
    color: #000;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #828DD6;
    color: #ffffff;
    cursor: default;
    z-index: 2;
}

.peel-shadows::before {
    background: url("/app/content/images/application.v1355170819.png") -63px -483px no-repeat;
    content: '';
    display: block;
    height: 7px;
    left: 6px;
    position: absolute;
    bottom: -8px;
    width: 110px;
}

.peel-shadows::after {
    background: url("/app/content/images/application.v1355170819.png") -173px -483px no-repeat;
    content: '';
    display: block;
    height: 7px;
    position: absolute;
    right: 6px;
    bottom: -8px;
    width: 110px;
}


/*------------------- General Class -----------------*/

.w5 {
    width: 5px;
}

.w10 {
    width: 10px;
}

.w15 {
    width: 15px;
}

.w20 {
    width: 20px;
}

.w30 {
    width: 30px;
}

.w40 {
    width: 40px;
}

.w50 {
    width: 50px;
}

.w60 {
    width: 60px;
}

.w70 {
    width: 70px;
}

.w80 {
    width: 80px;
}

.w90 {
    width: 90px;
}

.w100 {
    width: 100px;
}

.w120 {
    width: 120px;
}

.w125 {
    width: 125px;
}

.w150 {
    width: 150px;
}

.w175 {
    width: 175px;
}

.w200 {
    width: 200px;
}

.w20per {
    width: 20%;
}

.m0 {
    margin: 0 !important;
}

.m30 {
    margin: 30px !important;
}

.m5 {
    margin: 5px;
}

.m10 {
    margin: 10px;
}

.mt0 {
    margin-top: 0px !important;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mt30 {
    margin-top: 30px;
}

.mt60 {
    margin-top: 60px;
}

.mb0 {
    margin-bottom: 0 !important;
}

.mr0 {
    margin-right: 0;
}

.mr5 {
    margin-right: 10px;
}

.mr10 {
    margin-right: 10px;
}

.mr20 {
    margin-right: 20px;
}

.mr30 {
    margin-right: 30px;
}

.mb5 {
    margin-bottom: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mr10-imp {
    margin-right: 10px !important;
}

.ml10-imp {
    margin-left: 10px !important;
}

.ml4 {
    margin-left: 4px;
}

.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.ml15 {
    margin-left: 15px;
}

.ml20 {
    margin-left: 20px;
}

.ml25 {
    margin-left: 25px;
}

.ml30 {
    margin-left: 30px;
}

.ml50 {
    margin-left: 50px;
}

.ml70{
    margin-left: 70px;
}

.addressdiv {
    margin-left: 113px;
}

.p0 {
    padding: 0px;
}

.ptb5 {
    padding: 5px 0;
}

.p10 {
    padding: 10px;
}

.p10-imp {
    padding: 10px !important;
}

.p20 {
    padding: 20px;
}

.p30 {
    padding: 30px;
}

.pt10 {
    padding-top: 10px;
}

.pt8 {
    padding-top: 8px;
}

.p5 {
    padding: 5px;
}

.pl5 {
    padding-left: 5px;
}

.pl20 {
    padding-left: 20px;
}

.plr10 {
    padding: 0 10px;
}

.plr15 {
    padding: 0 15px;
}

.pb30 {
    padding-bottom: 30px;
}

.f12 {
    font-size: 12px;
}

.f14 {
    font-size: 14px;
}

.f16 {
    font-size: 16px;
}

.f18 {
    font-size: 18px;
}

.f24 {
    font-size: 24px;
}

.line-add {
    line-height: 23px;
    margin-left: 3px;
    vertical-align: top;
}

.btn {
    font-size: 12px;
}

.remove-margin {
    margin-top: 2px !important;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
}

.fa-trash {
    color: #e45454 !important;
}

.fa-ban {
    color: #d04f05 !important;
}

.fa-pencil {
    color: #304A6E !important;
}

h5 {
    font-weight: bold;
}

.rounded-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    padding: 10px;
}

.role-tree {
    border: solid 1px black;
    padding: 50px;
}

.inner-round {
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    position: relative;
}

.show-all {
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    margin-left: 2px;
    text-decoration: underline;
    vertical-align: middle;
}

table .lg-width {
    width: 250px;
}

table .md-width {
    width: 200px;
}

table .xmd-width {
    width: 150px;
}

table .sm-width {
    width: 90px;
}

table .xsm-width {
    width: 50px;
}

table .xxsm-width {
    width: 25px;
}

.lg-label {
    width: 490px !important;
}

.sm-label {
    width: 165px !important;
}

.sm-label-offset {
    margin-left: 172px !important;
}

.md-label{
    width: 325px !important;
}

.xsm-label {
    width: 130px !important;
}

.xxsm-input {
    width: 50px !important;
}

.xsm-input {
    width: 75px !important;
}

.sm-input {
    width: 100px !important;
}

.lg-input {
    width: 125px !important;
}

.xlg-input {
    width: 150px !important;
}

.xxlg-input {
    width: 200px !important;
}

.table-bordered>thead>tr>th>a {
    color: #fff !important;
    /* color: black !important; */
}

.table-bordered>tbody>tr>td ul li .btn {
    font-size: 12px;
}

.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>th {
    padding: 5px 8px;
    border: 0 none;
}

.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td,
.table-bordered>thead>tr>td {
    padding: 5px 8px;
    vertical-align: middle;
}

.txt-disabled {
    color: #ff0000;
    font-weight: 600;
}

.txt-enabled {
    color: #449D44;
    font-weight: 600;
}

.action-bar a {
    cursor: pointer;
}


/* .btn-warning {
    background-image: linear-gradient(to bottom, #ffffff 0px, #e0e0e0 100%);
    background-repeat: repeat-x;
    border-color: #cccccc;
    cursor: pointer;
    text-shadow: 0 1px 0 #ffffff;
    color: #000;
} */

input[type="text"],
input[type="password"],
textarea,
.all-form select,
.role-signup select,
.document-form select {
    /*background-color: rgb(252, 253, 253);*/
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 1px #e5e5e5 inset;
    /* width: 200px; */
    /*border-color: #e5e5e5;*/
    padding: 5px;
}

textarea:focus,
input:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=checkbox]:focus,
input[type=radio]:focus,
input[type=file]:focus,
select:focus,
a:focus,
button:focus {
    /* outline: none !important; */
}

.user-form span {
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0 4px;
}

.nav-tabs {
    border-bottom: 0 none;
    margin-left: 10px;
}

t .nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    background-color: #90C05E;
    color: #181818;
    background: linear-gradient(rgb(182, 224, 38) 0%, rgb(171, 220, 40) 100%) rgb(221, 239, 180) !important;
}

.actived {
    background: linear-gradient(#b6e026 0%, #abdc28 100%) #ddefb4 !important;
}

.actived {
    box-shadow: inset 0px 0px 5px #939393;
    background: #b6e026;
    background: -moz-linear-gradient(top, #b6e026 0%, #abdc28 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #b6e026), color-stop(100%, #abdc28));
    background: -webkit-linear-gradient(top, #b6e026 0%, #abdc28 100%);
    background: -o-linear-gradient(top, #b6e026 0%, #abdc28 100%);
    background: -ms-linear-gradient(top, #b6e026 0%, #abdc28 100%);
    background: linear-gradient(to bottom, #b6e026 0%, #abdc28 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#b6e026', endColorstr='#abdc28', GradientType=0);
    padding: 7px 12px;
    background: linear-gradient(rgb(182, 224, 38) 0%, rgb(171, 220, 40) 100%) rgb(221, 239, 180) !important;
}

.nav>li>a {
    padding: 4px 7px;
    background-color: #1884B5;
    color: #fff;
    border-radius: 5px;
    margin-top: 4px;
    font-family: Arial;
}

.nav>li>a:focus,
.nav>li>a:hover {
    background-color: rgba(43, 148, 191, 0.70);
}

.down-arrow {
    color: #5cb85c;
    font-size: 30px;
    margin-top: -3px;
    transform: scale(-1);
}

.arrow-wout-color {
    color: #919191;
    font-size: 30px;
    margin-top: -3px;
    transform: scale(-1);
}

.close {
    display: block;
    text-align: right;
    width: 100%;
}


/*------------------- Header class --------------------*/

.light-blue {
    background-color: #074474 !important;
}

.header-light-gray {
    background-color: #fafafa;
}

.header-gray {
    background-color: #d6d3d3;
    /* min-height: 26px; */
}

.header-sky {
    background-color: #E5EDF2;
}

.selected {
    background-color: #a4d2ee !important;
}


/*------------------- End General Class -----------------*/


/*------------------- DashboardStaff -------------*/

.dash-header {
    padding: 15px !important;
}

.dash-table .bt-width td {
    border-bottom-width: 0px;
}

.inner-border-lr {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.border-background-none {
    border: none !important;
    background: none !important;
}

.displaygrid {
    display: grid
}


/*---------------- User Account ----------------*/

#searchdata {
    height: auto !important;
}

.search-controls {
    background-color: #fafafa;
    border: 1px solid #dddddd;
    display: table;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-top: 12px;
    width: 100%;
}

.search-info {
    background-color: #fafafa;
    border: 1px solid #dddddd;
    margin-top: 10px;
    overflow: hidden;
    padding-bottom: 10px;
    padding-top: 12px;
    display: inline-block;
    width: 100%;
}

.search-info a i {
    color: rgba(25, 24, 24, 0.68);
    cursor: pointer;
    line-height: 30px;
    margin-right: 14px;
    vertical-align: top;
}

.gridUpperDiv {
    background: #eee none repeat scroll 0 0;
    border: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
    margin-bottom: -3px;
    overflow: hidden;
    padding: 4px;
}

.gridUpperDiv span {
    display: inline-block;
    line-height: 25px;
    margin: 5px 7px 0 10px;
    vertical-align: top;
    cursor: pointer;
}

.down_arrow {
    background: rgba(0, 0, 0, 0) url("/app/content/images/down-left-arrow.png") no-repeat scroll left center;
    display: block;
    float: left;
    margin: 4px 5px 0 2px;
    padding: 11px 10px;
}

.gridUpperDiv a.small.gray {
    padding: 2px 15px !important;
    float: left;
    border-left: 1px solid #595959;
    border-right: 1px solid #595959;
    border-top: 1px solid #595959;
    border-bottom: 1px solid #595959;
}

.gridUpperDiv a.pill-left {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.gridUpperDiv a.pill-right {
    border-bottom-left-radius: 0;
    border-left: 0 none !important;
    border-right: 1px solid #595959;
    border-top-left-radius: 0;
}

.small.gray {
    background-color: #8B8B8B;
    color: #fff;
}

.close:after .search-info {
    display: none;
}

.control-block {
    display: inline-block;
}

.control-block input[type="text"].required-text,
.control-block select.required-text {
    margin-bottom: 5px;
    display: block !important;
}

.permission-container {
    margin: 20px auto;
    padding: 6px;
    background: #f5f5f5;
    border-radius: 5px;
}

.all-form .form-div,
.role-signup .form-div,
.role-signup .form-div,
.document-form .form-div {
    line-height: 25px;
    position: relative;
}

.all-form .form-div label,
.role-signup .form-div label,
.document-form .form-div label {
    width: 100px;
    text-align: right;
    margin-right: 8px;
    font-weight: normal;
}

.all-form .form-div label.xsm-label {
    width: 118px !important;
}

.all-form .form-div input,
.all-form .form-div select,
.document-form .form-div input,
.document-form .form-div select {
    display: inline-block !important;
}

.all-form .form-div input.ng-hide:not(.ng-hide-animate) {
    display: none !important;
}

.all-form .form-div select.ng-hide:not(.ng-hide-animate) {
    display: none !important;
}

.all-form .form-div select[disabled] {
    background-color: rgb(235, 235, 228);
}

.all-form .form-div.check-mailing label {
    width: 380px;
}

.password-rule {
    padding: 15px 25px 5px;
}

h4,
h3,
h2 {
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    margin: 0 0 10px;
    padding: 0;
}

.password-rule p {
    margin-bottom: 10px;
}

.useraccount-example {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 15px 12px;
    margin: 0 26px;
}

.heading span {
    font-weight: bold;
    font-size: 14px;
}

.useraccount-example .row {
    margin-top: 10px;
}

.holder {
    background-color: #fafafa;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid #d3d3d0;
    color: #41372b;
    overflow: hidden;
    padding: 10px 8px;
    width: 100%;
    z-index: 1;
}

.user-table tr th {
    border-bottom: 0 none !important;
    color: white;
}

.table.uaccount-table {
    width: 63%;
}

.uaccount-table tr .sm-width,
.uaccount-table tr td {
    width: 57px !important;
    padding: 3px !important;
}

.dash-table tr td {
    color: #000 !important;
    border-color: #b4b4b4 !important;
}

.user-table ul li {
    display: inline-block;
    margin: 0 2px;
    cursor: pointer;
}

.user-table ul.uib-datepicker-popup li {
    display: list-item;
}

.user-table ul li i {
    color: #000;
    font-size: 16px;
    cursor: pointer;
}

.user-table ul li a {
    text-decoration: none;
}

.user-table td i {
    cursor: pointer;
}

.user-table ul li span {
    margin-left: 2px;
}

.fa-search {
    font-size: 18px;
}

.right-blk-ifont {
    float: right;
    margin-top: 4px;
}

.right-blk-ifont i {
    font-size: 18px;
}

.Editbtn {
    font-size: 14px;
    /* color: rgb(255, 255, 255) !important; */
    /* color: black !important; */
    border-style: solid;
    border-width: 1px 3px 3px 1px;
    border-color: #69f #5784E5 #5784E5 #69f;
    margin-left: 20px;
    padding: 2px 7px;
    background: #4479BA;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: solid 1px #20538D;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.small {
    padding: 2px 8px 2px 8px;
    font-size: 12px !important;
    line-height: 16px;
    border-radius: 3px;
    font-weight: normal !important;
}


/*---------------- End User Account ----------------*/


/*--------------- Register ----------------*/

.box-register {
    padding: 25px 30px 5px !important;
}


/*---------------- Roles ------------------*/

.action-bar {
    background: #e5e5e5 none repeat scroll 0 0;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    padding: 5px 12px;
}

.role-signup {
    margin: 0 auto;
    width: 50%;
}

.role-signup .form-div label,
.document-form .form-div label,
.all-form label {
    /*width: 120px;*/
    vertical-align: top;
}

.role-signup .form-div input,
.role-signup .form-div select {
    display: inline-block;
}

.role-detail {
    margin: 20px auto;
    padding: 6px;
}

.btn-role {
    padding: 4px;
}

.input2xwidth {
    width: 580px !important;
}

.toolBar {
    background: #eeeeee none repeat scroll 0 0;
    border: 1px solid #dddddd;
    margin-top: 5px;
    overflow: hidden;
    padding: 4px;
}

.toolBar-form,
.toolbar-form {
    background: rgb(250, 250, 250);
    border: 1px solid #dddddd;
    padding: 15px 5px;
}

.toolbar-buttom,
.toolbar-bottom {
    background: #eeeeee none repeat scroll 0 0;
    border-top: 1px solid #ffffff;
    margin-bottom: 5px;
    overflow: hidden;
    padding: 4px;
    border-left: 1px solid #d8d7d7;
    border-right: 1px solid #d8d7d7;
    border-bottom: 1px solid #d8d7d7;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.04);
}

.toolBar-form .fa {
    float: left;
}

.payment-processed .confirm {
    display: inline-block;
    line-height: 12px;
    cursor: pointer;
    position: absolute;
    right: 2px;
    pointer-events: all;
    background: green;
}

.per-data {
    overflow: hidden;
    padding: 4px 0;
    border-bottom: 1px solid #ddd;
}

.per-data:last-child {
    border-bottom: 0 none;
}

.sub-part1 {
    display: block;
    margin-left: 35px;
    padding-top: 10px;
    overflow: hidden;
}

.pertxt li {
    display: inline-block;
    font-size: 13px !important;
    line-height: 28px;
    margin-bottom: 0;
    margin-right: 14px !important;
    text-transform: uppercase;
}

.lblpagename {
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
}

.lblpagename-sub {
    margin-left: 42px;
    font-weight: bold;
    font-size: 13px;
}

.popup-title {
    background-color: #E5E5E5;
    display: inline-block;
    padding: 5px;
    width: 100%;
}

.chk-block {
    display: inline-block;
    margin-right: 44px;
}

.chk-block>span>input {
    margin-top: -2px;
}

.board {
    height: auto;
}


/*---------------- End Roles ------------------*/


/*-------------------- Automated -----------------*/

.automated-div label {
    width: 110px !important;
}

.automated-div input {
    width: 190px !important;
}


/*------------------ Password Management ---------------*/

hr {
    border-color: #dedede;
}

.header-blue {
    background-color: #074474;
}

.header-blue div {
    color: #fff;
    font-family: Verdana;
    padding: 5px 12px;
}

.header-blue p {
    display: inline-block;
    color: #fff;
    margin: 0;
}

.inner-body {
    margin-top: 22px;
    padding: 0px 10px;
}

.inner-body .user-form {
    margin-bottom: 10px;
}

.inner-body .user-form span {
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0 4px;
}

.user-block .inner-body .table-bordered tr th,
.user-block .inner-body .table-bordered tr td {
    border: 1px solid #6d9eb8 !important;
}

.passmang-pagination {
    overflow: hidden;
}

.passmang-pagination .pagination {
    margin: 10px 0;
}

.btn-passmanage {
    overflow: hidden;
    margin-bottom: 10px;
}

.btn-passmanage .btn-disabled {
    background-color: #9d9999;
    color: #fff;
}


/*-------------------- Document List -----------------*/

.doc-first-div {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.doc-savecode {
    line-height: 33px;
    margin-left: 10px;
}

.document-form {
    margin: 18px auto;
    width: 76%;
}

.dificency-top {
    vertical-align: top;
    line-height: 23px;
}


/*-------------------- Application Text ---------------*/

.apptext-header span {
    margin-right: 5px;
}

.apptext-header .show-all {
    vertical-align: top;
    font-size: 13px;
    line-height: 23px;
    margin-left: 5px;
}


/*------------------- Council info ---------------*/

.up-div {
    margin-bottom: 6px;
}

.mt-10 {
    margin-top: 10px;
}

.councilinfo h4 {
    font-size: 15px;
}

.council-input input {
    width: 645px !important;
}

.council-address input {
    margin-top: 8px;
    vertical-align: top;
    width: 22px !important;
}

.council-address span {
    font-size: 13px;
    color: #515151;
    font-weight: bold;
    line-height: 29px;
    margin-left: 5px;
    vertical-align: top;
}

.coun-heading {
    display: block;
    font-size: 11px;
    margin-top: -10px;
}

label.input-label.required {
    background: url('/app/content/images/requiredsymbol.png') no-repeat;
    background-position: right -2px;
}

label.input-label {
    padding-right: 10px;
}


/*----------------- Message and Bulletin -----------*/

table.dataTable {
    border-collapse: collapse !important;
}

.msg-show-all {
    margin: 0 !important;
}

.msg-bulletin h3 {
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    margin: 0;
    padding: 3px;
}

.brdr {
    border: 1px solid rgb(221, 233, 235) !important;
    box-shadow: inset 0px 0px 3px #DBDBDB;
}

.template-content td {
    border-color: #ddd !important;
}


/*------------------ Print Queue ---------------*/

.printstart-date {
    width: 102px !important;
    margin-right: 9px;
}


/*------------------ Individual -------------*/

.content {
    padding: 15px 8px 18px;
}

.print-dropdown {
    border-radius: 0;
    left: 584px;
    position: absolute;
    top: 55px;
    width: 50px;
}

.brandytest {
    border-right: 1px solid #dddddd;
    height: 427px !important;
}

.brandytest span b {
    color: #000 !important;
}

.individual-status-amg {
    width: 315px;
    overflow: hidden;
    margin: 0 auto;
}

.individual-status-amg span {
    margin-top: 3px;
    font-weight: bold;
    color: #fff;
}

.left-individual-block .left-block {
    width: 311px !important;
}

.left-individual-block .right-block {
    width: 908px !important;
    float: left;
    /* width: 759px !important; */
}

.individual-person-ul .individual-person-li {
    display: block;
    margin-bottom: 0;
}

.individual-person-ul .individual-person-li>div {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
}

.individual-person-ul li {
    line-height: 14px;
    margin-bottom: 14px;
    vertical-align: top;
}

.individual-name-listadd label {
    width: 150px !important;
}

.individual-name-listadd input,
.individual-name-listadd select,
.individual-name-listadd textarea {
    width: 160px !important;
}

.statusicon {
    color: #07b207;
}

.individual-education .form-div label {
    width: 429px !important;
}

.custom-typeahead {
    background-color: #fdfdfd;
    padding: 0px;
    width: 635px !important;
    z-index: 10000;
    margin-left: 176px;
    margin-top: -6px;
    border-radius: 5px;
    border: none;
}

.custom-typeahead li {
    padding: 2px 10px;
    border-bottom: 1px solid #fbfbfb;
    border-top: 1px solid #fbfbfb;
}

.custom-typeahead li:hover {
    cursor: pointer;
    background-color: #f7f7f7;
    border-bottom: 1px solid #f6f6f6;
    border-top: 1px solid #f6f6f6;
}

.custom-typeahead-control {
    box-shadow: none;
}

.individual-details .form-div {
    line-height: inherit;
}

.individual-details .form-div label {
    width: 107px !important;
    text-align: left;
}

.individual-info {
    /*margin-top: 30px;*/
    margin-top: 5px;
}

.ml-12 {
    margin-left: -12px;
}

.backoffice-big-textbox {
    width: 546px !important;
}

.individual-info label {
    /* width: 100px !important; */
}

.govt-div .large-label {
    width: 205px !important;
}

.individual-info .input-width,
.individual-info select {
    /*width: 137px !important;*/
}

.individual-textbox input,
.individual-textbox select,
.individual-textbox textarea {
    box-shadow: 0 1px 4px 0 rgba(176, 199, 221, 0.3) inset;
    border: 1px solid #ccc;
    background-color: #fcfdfd;
}

.individual-info .radio-div {
    width: inherit !important;
    position: inherit !important;
    margin-right: 5px;
}

.individual-info ul {
    padding-left: 25px;
}

.individual-person label {
    width: 160px !important;
}

.individual-person .input-width {
    width: 160px !important;
}

.search-individual-input label {
    width: 160px !important;
}

.search-individual-input input {
    width: 668px !important;
}

.print-btn {
    display: inline-block !important;
    float: right;
    margin-top: 5px;
    color: rgb(153, 157, 161) !important;
    padding: 0 !important;
    margin-right: 20px;
}

.print-btn:hover {
    background-color: #eee !important;
    background-image: inherit !important;
}

.prefered-error .nat-err-msg {
    right: inherit;
    top: 42px;
}

.edu-fullname-error .nat-err-msg {
    right: 133px;
    top: 60px;
}

.notes-error .nat-err-msg {
    top: 76px;
    right: 133px;
}


/*------------------- Renewal -----------------*/

.nev-business label {
    width: 180px !important;
}

.nev-business .flsApplReqForm-long input {
    width: 628px !important;
}

.branch-service label {
    width: 276px !important;
}

.lirenew-info {
    position: absolute;
    right: 13px;
    top: 13px;
}

.lirenew-info span {
    font-size: 14px;
}

.lirenew-info label {
    font-size: 14px;
    margin-right: 3px;
    margin-bottom: 3px;
}

.legal-info {
    margin: 10px 0 0;
    line-height: 20px;
}

.legal-info span {
    float: left;
    height: 43px;
    margin-right: 10px;
}

.legal-info .radio-inline .remove-margin {
    margin-top: 2px !important;
}

.legal-info button span {
    float: none;
    height: 30px;
    margin-right: 0px;
}


/*----------------------- Dashboard Applicant----------------*/

.dashboard-content {
    font-size: 15px;
}


/*---------------- Licensee Renewal --------------*/


/*----------------- 30-05-2016 -----------------*/

.applicationform {
    border-radius: 0;
}

.inactivelicense {
    display: block;
    margin-bottom: 17px;
    margin-top: 28px;
    padding: 10px;
}

.inactivelicense .checkbox {
    width: 752px;
    margin: 0 auto 0 106px;
}

.title-active-license {
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    margin-top: -4px;
}

.title-active-licenseinfo {
    line-height: 21px;
    margin-top: -2px;
    font-size: 14px;
    width: 582px;
    margin-right: 33px;
    margin-left: 10px;
    float: right;
}

.inner-left {
    margin-left: 42px !important;
}

.legal-right-div {
    margin-left: -17px !important;
}

.residential-check input {
    width: inherit !important;
}

.residential-check label {
    font-weight: normal;
    display: inline-block;
    vertical-align: top;
    line-height: 21px;
    width: inherit !important;
}

.emp-btn-add {
    text-align: right;
    margin: 10px 79px 10px 0;
}

.emp-right-div {
    margin-left: -26px;
}

.certification-update {
    margin-bottom: 20px;
}

.certification-account {
    margin-left: 61px;
}

.chk-certi-first {
    margin-left: 21px;
}

.chk-certi-first input {
    margin-right: 10px !important;
}

.chk-certi-sec input {
    margin-right: 10px !important;
}

.app-div {
    margin-left: 22px;
}

.apprentice-radio {
    vertical-align: top;
    line-height: 16px;
    margin-left: 22px;
}

.apprentice-long-div input {
    width: 608px !important;
}

.apprentice-fst-radio {
    margin-right: 10px !important;
}

.apprentice-sec-radio {
    margin-left: 14px !important;
    margin-right: 10px !important;
}

.apprentice-from {
    margin-left: -10px;
}

.apperntice-name {
    display: inline-block;
    width: 264px !important;
}

.apperntice-name label {
    width: 79px !important;
}

.apperntice-name input {
    width: 155px !important;
}

.apprentice-label label {
    width: 256px !important;
}

.apprentice-label input {
    width: 512px !important;
}

.apprentice-csz .address-c,
.apprentice-csz .address-s,
.apprentice-csz .address-z {
    width: 233px;
}

.apprentice-csz label {
    width: 67px !important;
}

.apprentice-csz input,
.apprentice-csz select {
    width: 157px !important;
}

.apprentice-fml .address-c,
.apprentice-fml .address-s,
.apprentice-fml .address-z {
    width: 280px;
}

.apprentice-wi .address-c,
.apprentice-wi .address-s,
.apprentice-wi .address-z {
    width: 270px;
}

.apprentice-wi label {
    width: 90px !important;
}

.apprentice-fml label {
    width: 120px !important;
}

.apprentice-fml input,
.apprentice-fml select {
    width: 140px !important;
}

.vetren-info .radio {
    margin-top: 30px;
}

.vetren-info .radio-inline {
    margin-top: 5px !important;
}

.vetren-title {
    width: 227px;
    float: left;
    margin-right: 10px;
}

.date-to {
    margin-left: -31px;
}

.edu-detail-div {
    width: 262px;
    display: inline-block;
}

.fee-info {
    color: #ff0000;
    font-weight: bold;
}

.fee-border-up {
    border-top: 1px solid rgba(128, 128, 128, 0.29);
}


/*-------------------------------------*/

.license-check input {
    width: 20px !important;
}

.license-check label {
    display: inline-block;
    font-weight: normal;
    line-height: 21px;
    vertical-align: top;
}

.residential-csz input,
.residential-csz select {
    width: 134px !important;
}

.residential-csz label {
    width: 74px !important;
}

.address-c {
    width: 246px;
    display: inline-block;
}

.address-s {
    width: 242px;
    display: inline-block;
}

.address-z {
    width: 244px;
    display: inline-block;
}

.status-acnumber {
    margin-top: 33px;
}

.applicationform {
    margin: 0 auto;
    width: 950px;
}

.applicationform .inner-app-form {
    min-height: 60px;
    border-bottom: 1px solid rgb(222, 222, 222);
    padding: 10px;
}

.get-started-content {
    min-height: 450px;
    overflow: hidden;
    padding: 0px 19px 18px;
}

.header-span {
    display: block;
    font-size: 18px;
    font-weight: bold;
    margin-top: 3px;
}

.header-active-in li div {
    font-size: 14px;
    line-height: 15px;
    vertical-align: top;
}

.sp-info input,
.sp-info select {
    width: 170px !important;
}

.flsApplReqForm {
    margin: 10px auto 25px;
    width: 95%;
    line-height: 26px;
}

.flsApplReqForm-long input {
    width: 619px !important;
}

.flsApplReqForm .all-form input,
.flsApplReqForm .all-form select {
    width: 180px;
}

.emp-info {
    width: 89%;
    margin: 0 auto 0 25px;
}

.child-support label {
    vertical-align: top;
    line-height: 15px;
}

.vetren-info span {
    vertical-align: top;
    line-height: 14px;
}

.legal-textarea {
    float: left;
    margin-left: 40px;
    width: 700px;
}

.edu-info input,
.edu-info select {
    width: 138px !important;
}

.date-services b {
    margin-left: 80px;
}

.date-services.newapp b {
    margin-left: 50px;
}

.date-services label {
    width: 47px !important;
}


/*--------------------------- Print Queue -----------------*/

.print-excel {
    float: right;
    margin-top: 8px;
    margin-right: 10px;
}

.print-excel i {
    font-size: 20px;
    margin-right: 3px;
}

.bootstrap-table tbody tr td {
    padding: 16px 4px;
}

.bootstrap-table tbody tr:first-of-type:not(.msgNorecord) td {
    padding-top: 32px;
}

.bootstrap-table tbody tr {
    font-weight: bold;
    text-align: center;
}

.bootstrap-table thead th,
.bootstrap-table thead th a {
    color: #fff;
}

.show-records-div .form-control {
    display: inline-block;
    margin: 5px;
    width: 55px;
}

.show-records-div input.form-control {
    display: inline-block;
    margin: 5px;
    width: 130px;
}

.show-records-div input.form-control.search {
    width: 180px;
}

.show-records-div label.control-label {
    vertical-align: middle;
}

.document-container {
    border: 1px solid #ddd;
    display: table;
    padding: 15px 5px;
    width: 100%;
}

.inner-submission {
    border-color: #ddd;
    border-image: none;
    border-style: solid;
    border-width: 1px 0;
    /*margin-left: -10px;
    margin-right: -10px;*/
}

.commentscroll {
    max-height: 200px;
    min-height: 60px;
    margin-bottom: 20px;
    overflow: auto;
}

.notesrepeater {
    padding-left: 0;
    text-align: justify;
}

label.control-label.isnotepublic {
    position: absolute;
    right: -1px;
    top: -1px;
    padding: 6px;
    border: 1px solid #097cb0;
    border-radius: 0px 5px 0px 5px;
    border-top: 0px;
    border-right: 0px;
    color: #fff;
    background-color: #074474;
    font-size: 12px;
    font-weight: normal;
}

label.control-label.notetype {
    position: absolute;
    left: -1px;
    bottom: -6px;
    padding: 6px;
    border: 1px solid #097cb0;
    border-radius: 0px 5px 0px 5px;
    border-top: 0px;
    border-right: 0px;
    color: #fff;
    background-color: #074474;
    font-size: 12px;
    font-weight: normal;
}

div.callout a.notetype.delete {
    display: none;
}

div.callout:hover a.notetype.delete {
    display: block;
}

a.notetype.delete {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px 12px;
    font-size: 14px;
}

.callout-outer {
    padding: 15px;
}

.callout.top-right::before {
    border-top: 10px solid #2e90bd;
    bottom: -20px;
    right: 17px;
}

.callout::before {
    border: 0.8em solid transparent;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
}

div.callout {
    background-color: #d4fdf4;
    background-image: -moz-linear-gradient(center top, #ddd, #eee);
    border: 1px solid #097cb0;
    border-radius: 3px;
    color: #000;
    margin-bottom: 15px;
    padding: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    min-height: 75px;
    padding-bottom: 40px;
}

.callout-outer span {
    font-size: 11px;
    font-style: italic;
}


/*----------------left tab----------------------------------------*/

.lapplication-tab {
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.lapplication-tab li {
    /* width: 229px; */
    margin-bottom: 8px;
}

.lapplication-tab li a {
    padding: 7px 12px;
    background-color: #ddefb4;
    color: #000;
}

.lapplication-tab li a i {
    background-color: #e75050;
    color: #fff;
    border-radius: 55px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    vertical-align: top;
    width: 21px;
    float: right;
    padding: 0 4px;
}

.license-input .input-width {
    width: 157px !important;
}

.license-input .input-xlg.input-width {
    width: 250px !important;
}

.license-input .input-width-3x {
    width: 635px !important;
}

.license-input .input-width-2x {
    width: 322px !important;
}

.input-fields .input-width {
    width: 150px !important;
}

.input-fields .input-width-3x {
    width: 472px !important;
}

.input-fields .input-width-4x {
    width: 620px !important;
}

.input-fields .input-width-520 {
    width: 520px !important;
}

.input-fields .input-width-2x {
    width: 322px !important;
}

.license-certification {
    width: 140px !important;
}

.lefttab-heading-name {
    font-size: 18px !important;
}

.lefttab-middleheading {
    font-size: 16px !important;
}

.versn-text {
    font-size: 15px !important;
}

.left-tab-positon {
    margin-top: 6px;
    width: 22%;
}

.right-tab-positon {
    width: 78%;
}

.backhome {
    font-size: 18px !important;
}

.inner-heading {
    font-size: 15px !important;
}

.padding-subheading {
    padding: 1px;
}

.displaynon {
    display: none;
}

.disable-button {
    background: #9D9999;
    color: #C8C6C6 !important;
}

.lapplication-tab.nav-tabs>li.active>a {
    padding: 7px 12px;
    /* background-color: #90C05E; */
    /* box-shadow: inset 0px 0px 5px #939393; */
    background: #b6e026;
    /*     background: -moz-linear-gradient(top, #b6e026 0%, #abdc28 100%); */
    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #b6e026), color-stop(100%, #abdc28)); */
    /* background: -webkit-linear-gradient(top, #b6e026 0%, #abdc28 100%); */
    /*     background: -o-linear-gradient(top, #b6e026 0%, #abdc28 100%); */
    /*     background: -ms-linear-gradient(top, #b6e026 0%, #abdc28 100%); */
    /* background: linear-gradient(to bottom, #b6e026 0%, #abdc28 100%); */
    /*     filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#b6e026', endColorstr='#abdc28', GradientType=0); */
    padding: 7px 12px;
    /* background: linear-gradient(rgb(182, 224, 38) 0%, rgb(171, 220, 40) 100%) rgb(221, 239, 180) !important; */
    color: #000;
}

.lapplication-tab li a:hover {
    background-color: rgb(182, 224, 38);
}

.license-input .input-width-2x {
    width: 400px !important;
}

.veteran-service {
    display: inline-block;
    max-height: 20px;
}

.error-container {
    color: red;
}

._720kb-datepicker-calendar {
    height: 260px;
    width: 260px !important;
}

.city-div {
    margin-left: 103px;
}

.license-input .input-width-4x {
    width: 647px !important;
}

.apprentice-csz .address-c,
.apprentice-csz .address-s,
.apprentice-csz .address-z {
    width: 247px;
}

.pading-notrequired {
    padding-right: 8px;
}

.norequred-space {
    padding-right: 8px;
}

.zipwidth-mini {
    width: 121px !important;
}

.dental-check input {
    float: left;
}

.chkdesign-long input {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 0;
    vertical-align: bottom;
    position: relative;
    top: -1px;
}

.chkdesign-long span {
    display: block;
    padding-left: 17px;
    text-indent: 0px;
    margin-top: -16px;
}

.btn-success {
    font-family: Verdana;
}

.zip-width {
    width: 122px !important;
}

.inner-print {
    width: 700px;
    margin: 0 auto;
}

.fix-width {
    width: 200px;
    overflow: hidden;
}

.fix-width2 {
    width: 130px;
    overflow: hidden;
}

.mb0 {
    margin-bottom: 0px;
}

.affidavit-sub-item {
    padding-left: 35px;
}

.address-state {
    width: 175px;
    float: left;
}

.address-state label {
    width: 35px !important;
}

.address-state select.input-width {
    width: 120px !important;
}

.address-zip label {
    width: 35px !important;
}

.address-zip input.input-width {
    width: 85px !important;
}

.education-label {
    width: 180px !important;
}

.text-align-left {
    text-align: left !important;
}

.city-state-label {
    width: 42px !important;
}

.city-50 {
    float: left;
    width: 250px;
}

.city-txt {
    width: 170px !important;
}

.datea-label {
    width: 105px !important;
}

.date-outer {
    float: left;
    width: 230px;
}

.major-label {
    width: 55px !important;
}

.major-txt {
    width: 100px !important;
}

.major-50 {
    float: left;
    width: 180px;
}

.application-list-table {
    color: #333333;
    border-collapse: collapse;
}

.application-list-table .input-th {
    width: 18px;
    max-width: 18px;
}

.application-list-table .th-2 {
    width: 28px;
    max-width: 28px;
}

.application-list-table .th-3 {
    text-decoration: underline;
    width: 40px;
    max-width: 40px;
}

.application-list-table .th-4 {
    text-decoration: underline;
    text-align: center;
    width: 100px;
    max-width: 100px;
}

.application-list-table .th-5 {
    text-decoration: underline;
    text-align: center;
    width: 100px;
    max-width: 100px;
}

.application-list-table .th-6 {
    text-decoration: underline;
    text-align: center;
    width: 155px;
    max-width: 155px;
}

.application-list-table .th-7 {
    text-decoration: underline;
    text-align: center;
    width: 95px;
    max-width: 95px;
}

.application-list-table .th-8 {
    text-decoration: underline;
    text-align: center;
    width: 95px;
    max-width: 95px;
}

.application-list-table .th-9 {
    text-decoration: underline;
    text-align: center;
}

.application-list-table .th-10 {
    width: 95px;
    max-width: 95px;
    text-align: center;
}

.application-list-table .td-1 {
    padding-left: 5px;
}

.application-list-table tbody tr td:first-child {
    padding-left: 8px;
}

.application-list-table .color-white {
    color: #fff;
}

.application-list-table .list-header {
    background-color: #074474;
    padding-left: 8px;
    color: #fff;
    line-height: 3;
}

.application-list-table .list-td {
    padding-top: 5px;
    padding-bottom: 5px;
}

.application-list-table .list-header a {
    color: #fff;
}

.fee-type {
    width: 300px !important;
    float: left;
}

.newApp .fee-type {
    width: 300px !important;
    float: none;
}


/* .profile-icon {
    height: 38px;
    width: 38px;
} */

.profile-icon {
    height: 32px;
    width: 25px;
    margin-top: -7px;
    margin-right: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.personal-fieldset {
    border: 1px dotted silver;
    padding-bottom: 9px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 4.2px;
    width: 96%;
    margin-left: 14px;
}

.personal-fieldset legend {
    margin-left: inherit;
    width: auto;
    border-bottom: none;
}

.light-middle {
    font-weight: normal;
    vertical-align: middle !important;
}

.input-search-name {
    width: 583px !important;
}

.action-btn {
    margin-top: 3px;
    vertical-align: top;
}

.action-btn2 {
    margin-top: 3px;
}

.list-icons .small {
    padding: 2px 8px 2px 8px;
    font-size: 12px !important;
    line-height: 16px;
    border-radius: 3px;
    font-weight: normal !important;
}

.list-icons .buttonGreen {
    background-image: -webkit-linear-gradient(top, #5cb85c 0, #419641 100%);
    background-image: -o-linear-gradient(top, #5cb85c 0, #419641 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5cb85c), to(#419641));
    background-image: linear-gradient(to bottom, #5cb85c 0, #419641 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff419641', GradientType=0);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #3e8f3e;
    cursor: pointer;
    /* color: #fff; */
    color: black;
    background-color: #5cb85c;
    border-color: #4cae4c;
    border: 1px solid #478839 !important;
}

.list-icons .buttonGreen:hover,
.buttonGreen:focus {
    background-color: #419641;
    background-position: 0 -15px;
}

.btn {
    color: white;
    border: none;
}

.btn.btn-default {
    color: black;
    border: solid 1px #ccc;
}

.btn-danger {
    background-color: #7D1F1C;
}


/*********************************Orange Button Start*****************************/

.list-icons .buttonPink {
    background-image: -webkit-linear-gradient(top, #f0ad4e 0, #eb9316 100%);
    background-image: -o-linear-gradient(top, #f0ad4e 0, #eb9316 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f0ad4e), to(#eb9316));
    background-image: linear-gradient(to bottom, #f0ad4e 0, #eb9316 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffeb9316', GradientType=0);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #e38d13;
    cursor: pointer;
}

.list-icons .buttonPink {
    background-color: #f0ad4e;
    color: #333;
    border-color: #333;
    border: 1px solid #333 !important;
    border-radius: 0;
}

.list-icons .buttonPink:hover,
.buttonPink:focus {
    background-color: #eb9316;
    background-position: 0 -15px;
}


/***********************************Button yellow***********************************************/

.list-icons .buttonYellow {
    background-image: -webkit-linear-gradient(top, #ffcc00 0, #ffcc00 100%);
    background-image: -o-linear-gradient(top, #ffcc00 0, #ffcc00 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffcc00), to(#ffcc00));
    background-image: linear-gradient(to bottom, #ffcc00 0, #ffcc00 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffcc00', endColorstr='#ffcc00', GradientType=0);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #333;
    cursor: pointer;
    background-color: #ffcc00;
    color: #333;
    border: 1px solid #333 !important;
    border-radius: 0;
}

.list-icons .buttonYellow:hover,
.buttonYellow:focus {
    background-color: #ffcc00;
    background-position: 0 -15px;
}


/***********************************Button White***********************************************/

.list-icons .buttonWhite {
    background-color: #fff;
    color: #333;
    border-color: #333;
    border: 1px solid #333 !important;
    border-radius: 0;
    cursor: pointer;
}

.list-icons .buttonWhite:hover,
.buttonWhite:focus {
    background-color: #fff;
    background-position: 0 -15px;
}


/***********************************Button Light Blue***********************************************/

.list-icons .buttonLightBlue {
    background-color: #b7dde8;
    color: #333;
    border-color: #333;
    border: 1px solid #333 !important;
    border-radius: 0;
    cursor: pointer;
}

.list-icons .buttonLightBlue:hover,
.buttonLightBlue:focus {
    background-color: #b7dde8;
    background-position: 0 -15px;
}


/*verify page css*/

.view-details-table {
    width: 98%;
    border: 1px solid #dedede;
    margin: 10px;
    background-color: #fafafa;
    line-height: 27px;
}

.view-details-table tr td {
    border-bottom: 1px dotted #dedede;
    padding-right: 15px;
}

.verify-table {
    border: 1px solid #dedede;
}

.verify-table td {
    border-bottom: 1px solid #dedede;
}

.color-white a {
    color: white;
}


/*end verify page css*/


/*Document Upload Control Starts here*/

.tabs-space .individual-person label {
    width: 140px !important;
}

.smaller-doc-control .doc-type-div {
    width: 100%;
}

.smaller-doc-control .individual-person label {
    width: 100px !important;
}

.smaller-doc-control .sm-label-offset {
    margin-left: 110px !important;
}

.highlight-deleted {
    background-color: #fde0e0;
}


/*Document Upload Control ends here*/


/* Query Builder ends here */

.licenseTable {
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.table>tbody>tr.endorsementRow>td {
    padding-top: 0px;
    padding-bottom: 0px;
}

.table-provision tbody tr:nth-child(1) td {
    padding-top: 10px;
    font-size: 12px;
}

.table.licenseTable>tbody>tr>td {
    border-top: none;
}

.width-col-1 {
    width: 250px !important;
}

.width-col-2 {
    width: 100px !important;
}

.width-col-3 {
    width: 100px !important;
}

.width-col-4 {
    width: 250px !important;
}

.width-col-5 {
    width: 100px !important;
}

.width-col-6 {
    width: 100px !important;
}

.delete-highlight {
    color: rgb(254, 0, 0);
    font-style: italic;
    text-decoration: line-through;
    text-decoration-color: black;
}

.selected-license {
    color: #074474;
}

.section-disabled {
    pointer-events: none;
    opacity: 0.5;
    display: block;
}

.section-disabled-visible {
    pointer-events: none;
    opacity: 0.8;
}

.additional-endorsement {
    width: 100%;
    float: right;
    margin-top: 16px;
}

.existing-endorsement {
    width: 100%;
    float: left;
}

.prev-license input.datefields {
    width: 70px;
    margin: 0px 2px 5px 2px;
    font-size: 12px;
}

.relativeposition {
    position: relative;
}

.relativeposition .expandcollapse {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
}

.full-screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: #fff;
}

.full-screen .editorstyle .ta-scroll-window.ta-editor {
    min-height: 400px !important;
    max-height: 400px !important;
}


/* Angular File drag drop file css starts here */

.drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    height: 65px;
    text-align: center;
    padding-top: 15px;
    width: 542px;
    font-size: 14px;
    /* color: #a18c8c; */
    color: black;
    display: inline-block;
    width: -moz-calc(100% - 180px);
    width: -webkit-calc(100% - 180px);
    width: calc(100% - 180px);
    position: relative;
    margin-bottom: 10px;
}

@media(max-width: 768px) {
    .drop-box {
        background: #F8F8F8;
        border: 5px dashed #DDD;
        height: initial;
        text-align: center;
        padding-top: 15px;
        width: 542px;
        font-size: initial;
        /* color: #a18c8c; */
        display: inline-block;
        width: initial;
        position: relative;
        margin-bottom: 10px;
    }
    label.pull-right {
        float: left!important;
    }
    .right-tab-positon {
        width: 100%;
    }
    .all-form .form-div label {
        text-align: left;
    }
    .apprentice-long-div input {
        width: 100% !important;
    }
    .license-input .input-width-4x {
        width: 100% !important;
    }
    .city-div {
        margin-left: initial !important;
    }
    .all-form .form-div input,
    .all-form .form-div select,
    .document-form .form-div input,
    .document-form .form-div select {
        display: block !important;
        width: 100% !important;
    }
    .license-input .input-width {
        width: 100% !important;
    }
    input[type="text"],
    input[type="password"],
    textarea,
    .all-form select,
    .role-signup select,
    .document-form select {
        width: 100% !important;
    }
    .apprentice-csz input,
    .apprentice-csz select {
        width: 100% !important;
    }
    .control-block {
        width: 100% !important;
    }
    .all-form .form-div,
    .role-signup .form-div,
    .role-signup .form-div,
    .document-form .form-div {
        min-width: initial !important;
    }
    .apprentice-csz .address-c,
    .apprentice-csz .address-s,
    .apprentice-csz .address-z {
        width: 100% !important;
    }
    table tbody tr td p {
        white-space: initial;
    }
    div[inlumon-content-editor] table,
    div[inlumon-content-editor] table colgroup col,
    div[inlumon-content-editor] table tbody tr,
    div[inlumon-content-editor] table tbody tr td {
        width: auto !important;
    }
    table[width],
    table[width] tr,
    table[width] tr td {
        width: auto;
    }
}

.smaller-doc-control .drop-box {
    height: 90px;
    width: -moz-calc(100% - 135px);
    width: -webkit-calc(100% - 135px);
    width: calc(100% - 135px);
}

.dragover {
    border: 5px dashed #074474;
}

.dragover-err {
    border: 5px dashed red;
}


/* Angular File drag drop file css ends here */

.clear-both {
    clear: both;
}

.middle-content {
    min-height: 500px;
}

.first-part {
    width: 34%;
    display: inline-block;
}

.second-part {
    width: 24%;
    display: inline-block;
}

.second-part .sm-label {
    width: 100px !important;
    text-align: right !important;
}

.second-part label {
    text-align: left !important;
}

.third-part {
    width: 39%;
    display: inline-block;
}

.third-part .sm-label {
    width: 120px !important;
}

.file-restriction {
    height: auto !important;
    font-style: italic;
    font-weight: 600;
    color: #908989;
    position: absolute;
    right: 0px;
    bottom: -35px;
    margin-right: 0px !important;
    font-size: 12px;
}

.drop-box .invalid {
    display: none;
}

.dragover-err .valid {
    display: none;
}

.dragover-err .file-restriction {
    color: red;
}


/* Editor css changes starts here */

.editorstyle .ta-scroll-window.ta-editor {
    min-height: 180px !important;
    max-height: 180px !important;
    overflow-y: auto;
}


/* .ta-scroll-window div:focus {
    outline: none;
} */

.editorstyle .ta-scroll-window.ta-editor div[ta-bind] {
    height: 100%;
}


/* Editor css changes ends here */

.licensekindlabels {
    padding-left: 180px;
}

.verify-license .licensekindlabels {
    padding-left: 255px;
}

.licensekindlabels label.prior {
    width: 80px;
}

.licensekindlabels label.issue {
    width: 70px;
    padding-left: 5px;
}

.licensekindlabels label.expiration {
    width: 80px;
}

.licensekindlabels label {
    font-size: 10px;
    font-weight: bold;
    width: 82px;
    text-align: center;
    margin-left: 4px;
    margin-right: 0px;
}

.licensekindlabels label.smaller {
    width: 75px;
}

.licensekindlabels label p {
    margin: 0px;
}

.all-form.legal-info textarea.form-control {
    overflow-x: hidden;
}

.rowsection {
    display: inline-block;
    width: 100%;
}

.prev-license select.small-select,
.prev-license input.datefields {
    width: 82px;
    margin-left: 2px;
}

.prev-license select.smaller-select {
    width: 75px;
    margin-left: 5px;
}

.priorIssueApplies .datefields.issue-date,
.priorIssueApplies .licensekindlabels .issue-date {
    margin-left: 192px;
}

.majorminor-table {
    margin: 5px 10px 10px 50px;
    width: 635px;
}

.majorminor-table tr th,
.table-gray tr th {
    background-color: #ddd;
    color: #2f2d2d;
}

.tblUploadSection tr td {
    padding-top: 10px;
}

.dnq-reason {
    margin-left: 260px;
}

.dnq-reason select {
    width: 85px;
    float: left;
}

.dnq-reason textarea {
    margin-left: 8px;
    overflow-x: hidden;
    width: 355px;
    min-height: 60px;
    resize: none;
}

.appfooter {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}

.fixed-table {
    table-layout: fixed;
    width: 100%;
}

.fixed-table th,
.fixed-table td,
.fixed-table span {
    word-wrap: break-word;
}

.font-red {
    color: red;
}

.color-red {
    color: red;
}

.color-info {
    color: #31b0d5;
}

.font-green {
    color: green;
}

.font-lt-green {
    color: #01a901;
}

.font-bold {
    font-weight: bold;
}

.font-bold-imp {
    font-weight: bold !important;
}

.font-normal {
    font-weight: normal !important;
}

.delete-enabled {
    float: left;
    width: 95%;
}

.delete-section {
    width: 40px;
    float: left;
}

.delete-section label {
    color: red;
    font-size: 12px;
    margin-top: 15px;
    font-weight: normal;
    margin-left: -2px;
}

.delete-kind {
    position: absolute;
    left: 20px;
}

.delete-end {
    position: absolute;
    left: -52px;
}


/* .prev-license input.datefields.prior-issue-date{
    margin-left: 100px;
} */

.prev-license .lic-type {
    margin-right: 100px;
}

.bgcheck .first-part {
    width: 34%;
}

.bgcheck .first-part .sm-label {
    width: 140px !important;
}

.bgcheck .second-part {
    width: 26%;
}

.bgcheck .second-part .sm-label {
    width: 70px !important;
}

.bgcheck .third-part {
    width: 39%;
}

.bgcheck .third-part .sm-label {
    width: 100px !important;
}

.bgcheck .fpmethod .sm-label {
    width: 140px !important;
}

.block-section {
    width: 100% !important;
    display: inline-block;
}

.block-section a i {
    font-size: 18px;
    margin: 5px;
}

.block-section a:hover {
    text-decoration: none;
}

.applink {
    color: blue;
    text-decoration: underline;
}

.prodev {
    display: inline-block;
}

.prodev p {
    float: right;
}

.ext-status {
    color: black;
    font-size: 14px;
    font-weight: normal;
    padding: 5px 8px;
    background-color: rgb(143, 236, 106);
}

.form-horizontal .form-control {
    width: 100%;
    margin-bottom: 0px;
    padding: 2px;
    height: auto;
}

.form-horizontal label {
    font-weight: normal;
    font-weight: bold;
}

.form-horizontal label.bold-text {
    font-weight: bold;
}

.form-horizontal .form-group {
    margin-bottom: 10px;
}


/* Individual Info search results starts here */

.ind-info-table {
    table-layout: fixed;
    color: #0000ff;
    text-decoration: underline;
    word-break: break-word;
}

.ind-info-table>tbody tr.active>td {
    background-color: rgb(143, 236, 106);
}

.ind-info-table>tbody tr:hover {
    background-color: rgb(213, 250, 198) !important;
    cursor: pointer;
}

.ind-info-table>tbody tr td,
.ind-info-table>thead tr th {
    border-top: 0 none;
    padding: 6px 3px;
}

.ind-info-table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ind-info-table th:nth-child(1) {
    width: 80px;
}

.ind-info-table th:nth-child(2) {
    width: 80px;
}

.ind-info-table th:nth-child(3) {
    width: 80px;
    padding-right: 2px;
}


/* Individual Info search results ends here */

.listing-icon {
    display: inline-block;
    margin: 0 0px;
}

.listing-icon img {
    height: 26px;
}

.listing-icon img.small-icon {
    height: 24px;
    margin-bottom: 2px;
}

.toolbar-block {
    display: inline-block;
}

.toolbar-underadd {
    float: right;
    width: 100%;
    margin-bottom: 1px;
    margin-top: 10px;
}

.inner-input-width {
    width: 157px !important;
}

.inner-section {
    display: inline-block;
}

.width2xAdd {
    width: 75% !important;
}

.section-disabled {
    pointer-events: none;
    opacity: 0.5;
    display: block;
}


/* Firms Styles */

.toolBar {
    background: #eeeeee none repeat scroll 0 0;
    border: 1px solid #dddddd;
    margin-top: 5px;
    overflow: hidden;
    padding: 4px;
}

.toolBar-form {
    background: rgb(250, 250, 250);
    border: 1px solid #dddddd;
    padding: 15px 5px;
    border-radius: 3px 3px 0px 0px;
}

.toolBar-action {
    background: #eeeeee none repeat scroll 0 0;
    border: 1px solid #dddddd;
    overflow: hidden;
    border-top: 1px solid #fffefe;
    padding: 4px;
}

.toolBar-buttom {
    background: #eeeeee none repeat scroll 0 0;
    border-top: 1px solid #ffffff;
    margin-bottom: 5px;
    overflow: hidden;
    padding: 4px;
    border-left: 1px solid #d8d7d7;
    border-right: 1px solid #d8d7d7;
    border-bottom: 1px solid #d8d7d7;
}

.header-internal-tabs {
    padding: 4px;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #cecece;
    border-bottom: 1px solid #d2d2d2;
    background-color: #F9F7F4;
    -webkit-box-shadow: inset 0px 1px 2px 0px #fff, 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 1px 2px 0px #fff, 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    color: #767676;
    background: #f3f3f3;
}

.header-internal-tabs.ind-info {
    margin: -15px -8px 10px -8px;
}

.header-internal-tabs i {
    color: #1884b5;
}

.header-internal-tabs div {
    font-family: Verdana;
    padding: 5px 12px;
}

.header-internal-tabs p {
    display: inline-block;
    /* color: #fff; */
    margin: 0;
}

.bold-text {
    font-weight: bold;
}

.font-red {
    color: red;
}

.displaytable {
    display: table;
}

.primary-source {
    position: relative;
}

.primary-source-seal {
    position: absolute;
    right: 60px;
    top: -85px;
}

.detail-container .left-col {
    width: 50%;
    float: left;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
}

.detail-container .right-col {
    padding-left: 10px;
    width: 50%;
    float: left;
    text-align: left;
    font-weight: normal;
    font-size: 14px;
}


/*PDf Section Css starts here*/

.pdf .showInPdf {
    display: block !important;
}

.pdf .definitelyShowInPdf {
    display: block !important;
}

.pdf tr.showInPdf {
    display: table-row !important;
    text-align: justify;
}

.pdf .hideInPdf,
.showInPdf,
.pdf .inner-heading,
.pdf .submit-panel {
    display: none !important;
}

.pdf .section-form {
    page-break-inside: avoid;
}

.pdf .section-form,
.pdf .section-form table,
.pdf .payment-table {
    font-size: 12px;
}

.pdf .newApplicationForm {
    width: 1024px;
    margin: 5px;
}

.pdf .textAreaReplaced {
    width: 100%;
    float: left;
}

.pdf .textAreaReplaced pre {
    word-break: normal;
    text-align: justify;
}

.pdf .experiencFormPartB{
    width:1024px;
}
/*PDf Section Css ends here*/

ul.original-list li {
    list-style-type: disc;
}

.collapse.in.inline {
    display: inline-block;
    width: 100%;
}

.notesbox {
    width: calc(100% - 180px) !important;
}

.inner-submission {
    width: 100%;
    padding: 5px;
    border-left: 0;
    border-right: 0;
    border: 1px solid #e6e6e6;
    display: inline-block;
    border-top: none;
}

.disable-controls input,
.disable-controls select,
.disable-controls textarea,
.disable-controls button,
.disable-controls a:not(.enabled),
.disable-controls .external-description-field,
.disable-controls .experience-description-field {
    background-color: #e6e6e6 !important;
    pointer-events: none;
}


.disable-controls input[type="checkbox"],
.disable-controls input[type="radio"] {
    background-color: #e6e6e6 !important;
    pointer-events: none;
}

.disable-controls label {
    pointer-events: none;
}

.warning-icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #8a8a8a;
    color: white;
    display: block;
    padding: 4px 2px 2px 2px;
    font-size: 13px;
}

.warning-icon:focus,
.warning-icon:hover {
    color: white;
}

.warning-icon-red {
    background-color: red;
}

.action-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 11px;
    display: inline-block;
    font-weight: bold;
    color: white;
    text-align: center;
    font-size: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

a.action-icon:hover {
    color: white;
}

.action-icon.warn {
    background-color: gray;
}

.action-icon.warn-red {
    background-color: red !important;
}

.action-icon.ld {
    background-color: skyblue
}

.action-icon.ce {
    background-color: dodgerblue
}

.action-icon.def {
    background-color: goldenrod;
}

.action-icon.sup {
    background-color: green;
}

.verification-icon {
    background: #fe0000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    padding: 1px 0px 0px 1px;
    margin-right: 5px;
}

.popover {
    max-width: 100%;
}

.icon-flipped {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

.deficiency-container {
    border: 1px solid #EEEEEE;
    padding: 10px;
    background-color: #fdfdfd;
}

.deficiency-container .deficiency {
    clear: both;
    width: 100%;
    margin-bottom: 5px;
    padding: 5px 10px;
    position: relative;
}

.deficiency-container .deficiency.deficiency-border{
    border: 1px solid #ccc;
}

.deficiency-container .deficiency .resolved-layover{
    position: absolute;
    top: 0;
    font-size: 20px;
    color: green;
    z-index: 1;
    border: 1px solid #67bb67;
    right: 0;
    text-align: center;
    padding: 5px;
}

.deficiency-list {
    width: 500px;
}

.deficiency-list input {
    vertical-align: top;
}

.deficiency-container .deficiency label {
    padding-left: 10px;
}

.deficiency-list span {
    display: inline-block;
    width: 455px;
}

.inputsecurity {
    font-family: 'security' !important;
    -webkit-text-security: disc;
}

@font-face {
    font-family: 'security';
    src: url('/app/content/fonts/securityFont/text-security-circle.ttf');
}

.tab-header {
    line-height: 30px;
    vertical-align: middle;
    font-size: 14px;
    padding: 0 10px;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.panel .panel-heading .panel-button-container {
    margin-top: -5px;
}

.panel .panel-heading .panel-control-container {
    margin-top: -10px;
}

.licensee-header .main-logo {
    height: 100px;
    width: 100px;
}

.licensee-header .board-name {
    font-size: 36px;
    line-height: 80px;
    vertical-align: middle;
    text-align: center;
}

.licensee-header .alternate-logo {
    height: 100px;
    width: 200px;
}

@media (max-width: 767px) {
    .licensee-header .main-logo {
        height: 60px;
        width: 60px;
    }
    .licensee-header .board-name {
        font-size: 22px;
        line-height: 22px;
        vertical-align: middle;
        text-align: center;
    }
    .licensee-header .alternate-logo {
        height: 50px;
        width: 100px;
    }
}

@media (min-width: 768px) {
    .licensee-header .main-logo {
        height: 100px;
        width: 100px;
        /* float: left; */
    }
    .licensee-header .board-name {
        font-size: 24px;
        line-height: 40px;
    }
    .licensee-header .alternate-logo {
        height: 100px;
        width: 250px;
        float: right;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (min-width: 992px) {
    .licensee-header .main-logo {
        float: none;
    }
    .licensee-header .board-name {
        font-size: 36px;
        line-height: 40px;
    }
    .licensee-header .alternate-logo {
        float: none;
    }
}

@media (min-width: 1200px) {
    .licensee-header .board-name {
        font-size: 36px;
        line-height: 40px;
        vertical-align: middle;
        text-align: center;
    }
}

@media (min-width: 1625px) {
    .licensee-header .board-name {
        font-size: 36px;
        line-height: 80px;
        vertical-align: middle;
        text-align: center;
    }
}

.scrollable-item {
    max-height: 200px;
    overflow-y: auto;
}

.content-default-behaviour ul{
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;
}

.content-default-behaviour ul li{
    list-style-type: disc;
}

.table-text-center th,.table-text-center td{
    text-align: center !important;
}

.input-width-md{
    width: 275px;
}

.reference-icon{
    width: 30px;
    font-size: 16px;
    height: 30px;
    display: inline-block;
}
.reference-icon i{
    width: 100%;
    height: 100%;
    padding-top: 6px;
    border-radius: 50px;
    padding-left: 0px;
    border: 1px solid #e6e6e6;
    box-shadow: 10px 10px 15px 8px  rgba(143,158,181,.14), 0 3px 14px 2px rgba(0,0,0,.12), 0 5px 5px -3px rgba(143,158,181,.2);
}


/*Qualifying Questions Start*/

.input2xwidth {
    width: 67.5% !important;
}

.displaytable {
    display: table;
}

.heading-Question {
    color: #455a64;
    font-size: 26px;
    margin-bottom: 20px;
}
.mrsvg{
        margin:0 -5px !important;
}
.width150{width:150px;}
.text-left{text-align:left;}
.correct-ans{
        background-color: #fff259;
}
.Question-top {
    font-style: normal;
    margin-bottom: 15px;
    word-spacing: 2px;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 2.8rem;
    color: #6b6c72;
}

.Question-inner {
    font-style: normal;
    margin-bottom: 0px;
    word-spacing: 2px;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 1.5rem;
    margin-top: 10px;
    float: left;
    line-height: 2.8rem;
    color: #6b6c72;
}

.exam-question-answers .radio {
    margin: 0.5rem;
    margin-bottom: 15px;
    margin-top: 15px;
}

/* .radio input[type="radio"] {
  position: absolute;
  opacity: 0;
} */

.radio input[type="radio"]+.radio-label:before {
    content: '';
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.7em;
    height: 1.7em;
    position: relative;
    top: -0.2em;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
}

.radio input[type="radio"]:checked+.radio-label:before {
    background-color: #3197EE;
    -webkit-box-shadow: inset 0 0 0 4px #f4f4f4;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.radio input[type="radio"]:focus+.radio-label:before {
    outline: none;
    border-color: #3197EE;
}

.radio input[type="radio"]:disabled+.radio-label:before {
    -webkit-box-shadow: inset 0 0 0 4px #f4f4f4;
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
}

.radio input[type="radio"]+.radio-label:empty:before {
    margin-right: 0;
}

/*Qualifying Questions End*/

.circle {
    border: 0.1em solid grey;
    border-radius: 100%;
    height: 2em;
    width: 2em;
    text-align: center;
}

.circle p {
	margin-top: 0.10em;
    font-size: 1.5em;
    font-weight: bold;
    font-family: sans-serif;
    color: grey;
}
.textbox-3x {
    width: 669px !important;
}

.tab-inner {
    border: 1px solid #dedede;
    padding: 0px;
    width: 95%;
    margin-left: 20px;
    background-color: white;
    z-index: 11;
    position: relative;
    margin-top: -12px;
}

.feature-box {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin-bottom: 15px;
    cursor: pointer;
    min-height: 20px;
    padding: 19px;
    text-align: center;
}

.feature-icon {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 10px;
}

.feature-icon i {
    font-size: 25px;
}

.feature-box:hover {
    background-color: #101525;
    border: 1px solid #e3e3e3;
    color: #959fae;
}
.feature-box.active {
    background-color: #101525;
    border: 1px solid #e3e3e3;
    color: #959fae;
}


/* external field css starts here */
.experience-text-field,.external-text-field{
    display: block;
}

.external-description-field,.experience-description-field{
    display:none;
    min-height: 50px;
}

.pdf .external-description-field,.pdf .experience-description-field{
    display:none;
}

.experience-fields .experience-text-field,.experience-fields .experience-description-field{
    display: block;
}

.experience-fields .external-description-field,.experience-fields .external-text-field{
    display: none;
}

.external-fields .experience-text-field,.external-fields .experience-description-field{
    display: none;
}

.external-fields .external-description-field,.external-fields .external-text-field{
    display: block;
}
/* external field css ends here */

.center-action{
    margin: 0 auto;
}

.queue-action-buttons p{
    margin-left:10px;
    float: left;
}

.full-width-no-margin{
    margin: 0 0 0 0;
    width: 100%;
}

.font-orange {
    color: orange;
}