.print-display {
    display: none;
}

@media print {
    body .container {
        width: 1024px !important;
    }
    .container .headerlicensee-middle-logo span {
        font-weight: 400;
    }
    .print-no-display {
        display: none;
    }
    .print-display {
        display: block;
    }
    .verify-table .print-color-black {
        color: black !important;
    }
    .container .headerlicensee-right-side-logo {
        position: unset;
    }
    .container .headerlicensee-sec-logo {
        float: left;
        width: 150px !important;
    }
    .container .headerlicensee-right-side-logo {
        float: left;
        width: 150px !important;
    }
    .container .headerlicensee-middle-logo {
        float: left;
        width: 724px !important;
    }
}