.button,
.button.medium:link,
.button.medium:active,
.button.medium:visited,
.button.medium {
    background-position: left -44px;
}

a.small {
    padding: 4px 8px 3px 8px;
    font-size: 12px !important;
    line-height: 17px;
    border-radius: 3px;
    font-weight: normal !important;
    text-decoration: none;
}

.small {
    padding: 2px 8px 2px 8px;
    font-size: 12px !important;
    line-height: 16px;
    border-radius: 3px;
    font-weight: normal !important;
}

.btn-warning{
    background: #5E3A08;
}
.btn[disabled]{
    opacity: .80;
}
.medium {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

@-moz-document url-prefix() {
    .expinputfirefox {
        color: red;
    }

    a.small {
        padding: 4px 8px;
        font-size: 12px !important;
        line-height: 16px;
        border-radius: 3px;
        font-weight: normal !important;
        text-decoration: none;
    }
}

/*********************************Secondary Button Start*****************************/

.secondary {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
    /*-webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075);*/
    text-shadow: 0 1px 0 #fff;
    background-image: -webkit-linear-gradient(top, #fff 0, #e0e0e0 100%);
    background-image: -o-linear-gradient(top, #fff 0, #e0e0e0 100%);
    /* background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e0e0e0)); */
    background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #dbdbdb;
    border-color: #ccc;
    cursor: pointer;
}

.secondary {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    border: 1px solid #C0C0C0 !important;
}

.secondary:hover,
.secondary:focus {
    background-color: #e0e0e0;
    background-position: 0 -15px;
}

/*********************************Green Button Start*****************************/
.buttonGreen {
    background-image: -webkit-linear-gradient(top, #5cb85c 0, #419641 100%);
    background-image: -o-linear-gradient(top, #5cb85c 0, #419641 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5cb85c), to(#419641));
    background-image: linear-gradient(to bottom, #5cb85c 0, #419641 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff419641', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #3e8f3e;
    cursor: pointer;
}

.buttonGreen {
    /* color: #fff; */
    background-color: #5cb85c;
    border-color: #4cae4c;
    border: 1px solid #478839 !important;
}

.buttonGreen:hover,
.buttonGreen:focus {
    background-color: #419641;
    background-position: 0 -15px;
}

/***********************************Button yellow***********************************************/

.buttonYellow {
    background-image: -webkit-linear-gradient(top, yellow 0, yellow 100%);
    background-image: -o-linear-gradient(top, yellow 0, yellow 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(yellow), to(yellow));
    background-image: linear-gradient(to bottom, yellow 0, yellow 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffeb9316', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #f0ad4e;
    cursor: pointer;
}

.buttonYellow {
    color: #fff;
    background-color: yellow;
    border-color: #f0ad4e;
    border: 1px solid rgb(203, 129, 24) !important;
}

.buttonYellow:hover,
.buttonYellow:focus {
    background-color: #eb9316;
    background-position: 0 -15px;
}

/*********************************Orange Button Start*****************************/

.buttonPink {
    background-image: -webkit-linear-gradient(top, #f0ad4e 0, #eb9316 100%);
    background-image: -o-linear-gradient(top, #f0ad4e 0, #eb9316 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f0ad4e), to(#eb9316));
    background-image: linear-gradient(to bottom, #f0ad4e 0, #eb9316 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffeb9316', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #e38d13;
    cursor: pointer;
}

.buttonPink {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
    border: 1px solid rgb(203, 129, 24) !important;
}

.buttonPink:hover,
.buttonPink:focus {
    background-color: #eb9316;
    background-position: 0 -15px;
}

.button:hover,
.button:focus {
    background-image: none;
    -moz-box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 1px 0px rgba(255, 255, 255, .5);
    -webkit-box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 1px 0px rgba(255, 255, 255, .5);
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 1px 0px rgba(255, 255, 255, .5);
}

.button.pill-left {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-left: 1px solid #595959;
    border-right: 1px solid #595959;
}

.button.small {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    font-size-adjust: none;
    font-stretch: normal;
    font-feature-settings: normal;
    font-language-override: normal;
    font-kerning: auto;
    font-synthesis: weight style;
    font-variant: normal;
    background-position: left 0px !important;
    height: 22px !important;
    line-height: 22px !important;
}

.button.pill-right {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px none;
    border-right: 1px solid #595959;
}

.button {
    /* text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4); */
    -moz-user-select: none;
    border-radius: 3px;
    background-color: #8C8C8C;
    /* background-image: url("../images/sprites.v1351539994.png"); */
    /*font: bold 14px/30px Arial,Helvetica,sans-serif;*/
    color: #FFF !important;
    text-decoration: none !important;
    text-align: center;
    display: block;
    height: 30px;
    float: left;
    cursor: pointer;
    white-space: nowrap;
    padding: 0px;
    margin: 0px;
    /* outline: 0px none; */
    box-shadow: 0px 2px 1px -1px #D2D2D2 inset;
    border: 1px solid #595959;
}

.button.small.gray {
    padding: 0px 15px !important;
    height: 22px !important;
    line-height: 22px !important;
}