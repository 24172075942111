.ck.ck-editor__main > .ck-editor__editable {
  min-height: 200px;
}

.ck.ck-editor__main ul {
  padding-left: 40px;
  list-style: disc;
}

.ck.ck-editor__main ol {
  padding-left: 40px;
  list-style: decimal;
}

.ck.ck-editor__main ul li {
  list-style: unset;
}

.ck.ck-editor__main ol li {
  list-style: unset;
}
