@media screen and (max-width: 1224px) and (min-width: 992px) {

    h4 {
        font-size: 12px;
        line-height: 20px;
    }

    /*Heading logo and name*/
    .header-middle-logo span {
        font-size: 19px;
        margin: 30px 0px 0px auto;
        width: 400px;
    }

    /* end*/
    .header .nav-mainlinks li {
        font-size: 12px;
    }

    .lefttab-middleheading {
        font-size: 14px !important;
    }

    .lapplication-tab li {
        /* width: 224px; */
        margin-bottom: 0px;
    }

    ul.nav-mainlinks li ul li a {
        font-size: 11px !important;
    }

    .full-width-dropdown {
        width: 70% !important;
    }

    .inner-input-width {
        width: 108px !important;
    }

    .license-input .input-width {
        width: 40% !important;
    }

    .license-input .input-xlg.input-width {
        width: 48% !important;
    }

    .responsiv-gender {
        margin-right: 10px;
    }

    .rs-genderwidth {
        width: 100%;
    }

    .textare-bigwith {
        width: 70% !important;
    }

    .checkbox-inline,
    .radio-inline {
        font-size: 10px;
        padding-left: 10px;
    }

/* input[type="text"],
    input[type="password"], */
    textarea,
    .all-form select,
    .role-signup select,
    .document-form select {
        /* width: 25%; */
    }

    .header-gray {
        margin-bottom: 6px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .header-gray h4 {
        margin-bottom: 0px;
    }
}


@media screen and (max-width: 993px) and (min-width: 765px) {
    .header-middle-logo {
        float: left;
        margin: 0 0px;
        padding-top: 10px;
    }

    .header-middle-logo span {
        font-size: 17px;
        width: 390px;
    }

    .header-fst-logo img {
        width: 70%;
        padding-top: 15px;
    }

    .header-sec-logo img {
        float: right;
        margin-top: 2px;
        width: 70%;
    }

    .header .nav-mainlinks li {
        min-width: 100px;
        font-size: 12px;
    }

    .header .nav-mainlinks li a {
        min-width: 100px;
    }

    .lapplication-tab li {
        width: 100%;
    }

    .lapplication-tab li a {
        font-size: 11px;
        padding: 5px 12px;
    }

    .nav>li>a>img {
        width: 12%;
    }
}