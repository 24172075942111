@media (min-width: 768px) {
    .form-horizontal .form-group div:nth-child(2) {
        margin-bottom: 10px;
    }

    .form-horizontal .form-group div:last-child {
        margin-bottom: 0px;
    }
}

@media (min-width: 992px) {
    .form-horizontal .form-group div:nth-child(2) {
        margin-bottom: 0px;
    }
}

.input-group-addon {
    cursor: pointer;
}

.input-group-addon-small {
    padding: 5px 12px;
}

.form-horizontal label {
    font-weight: normal;
}

.bg-highlight {
    color: #767676;
    background-color: #f3f3f3;
}

.bg-lightblue {
    color: #fff;
    background-color: #063464;
}

.bg-blue {
    color: #fff;
    background-color: #074474;
}

fieldset.fieldset {
    padding: .35em .625em .75em;
    margin: 0 2px;
    border: 1px solid silver;
}

fieldset.fieldset legend {
    width: auto;
    border-bottom: none;
    margin-bottom: unset;
    font-size: unset;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xlg-1,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12,
.col-xll-1,
.col-xll-2,
.col-xll-3,
.col-xll-4,
.col-xll-5,
.col-xll-6,
.col-xll-7,
.col-xll-8,
.col-xll-9,
.col-xll-10,
.col-xll-11,
.col-xll-12 {
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.btn-block {
    display: block;
    width: 100%;
}

.form-group {
    margin-bottom: 15px;
}

a {
    color: #337ab7;
    text-decoration: none;
}

.img-circle {
    border-radius: 50%;
}

.form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px;
}

label {
    margin-bottom: 5px;
}

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0px;
}

.dropdown-menu {
    padding: 5px 0;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: 0;
    border-radius: 0.25rem;
    display: none;
}

.dropdown-submenu:hover .dropdown-menu {
    display: block;
}

.dropdown-menu.show {
    display: block;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    color: #555;
    background-color: #e7e7e7;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 15px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu>li>a {
    padding: 3px 20px;
}

.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
}

/* panel css starts here */

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
    padding: 15px;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-heading>.dropdown .dropdown-toggle {
    color: inherit;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}

.panel-title>a,
.panel-title>small,
.panel-title>.small,
.panel-title>small>a,
.panel-title>.small>a {
    color: inherit;
}

.panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.list-group,
.panel>.panel-collapse>.list-group {
    margin-bottom: 0;
}

.panel>.list-group .list-group-item,
.panel>.panel-collapse>.list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
}

.panel>.list-group:first-child .list-group-item:first-child,
.panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel>.list-group:last-child .list-group-item:last-child,
.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 0;
}

.list-group+.panel-footer {
    border-top-width: 0;
}

.panel>.table,
.panel>.table-responsive>.table,
.panel>.panel-collapse>.table {
    margin-bottom: 0;
}

.panel>.table caption,
.panel>.table-responsive>.table caption,
.panel>.panel-collapse>.table caption {
    padding-right: 15px;
    padding-left: 15px;
}

.panel>.table:first-child,
.panel>.table-responsive:first-child>.table:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel>.table:first-child>thead:first-child>tr:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child {
    border-top-left-radius: 3px;
}

.panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child {
    border-top-right-radius: 3px;
}

.panel>.table:last-child,
.panel>.table-responsive:last-child>.table:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
}

.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
}

.panel>.panel-body+.table,
.panel>.panel-body+.table-responsive,
.panel>.table+.panel-body,
.panel>.table-responsive+.panel-body {
    border-top: 1px solid #ddd;
}

.panel>.table>tbody:first-child>tr:first-child th,
.panel>.table>tbody:first-child>tr:first-child td {
    border-top: 0;
}

.panel>.table-bordered,
.panel>.table-responsive>.table-bordered {
    border: 0;
}

.panel>.table-bordered>thead>tr>th:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:first-child,
.panel>.table-bordered>tbody>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
.panel>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-bordered>thead>tr>td:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
.panel>.table-bordered>tbody>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
.panel>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child {
    border-left: 0;
}

.panel>.table-bordered>thead>tr>th:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:last-child,
.panel>.table-bordered>tbody>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
.panel>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-bordered>thead>tr>td:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
.panel>.table-bordered>tbody>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
.panel>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child {
    border-right: 0;
}

.panel>.table-bordered>thead>tr:first-child>td,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
.panel>.table-bordered>tbody>tr:first-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
.panel>.table-bordered>thead>tr:first-child>th,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>th,
.panel>.table-bordered>tbody>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>th {
    border-bottom: 0;
}

.panel>.table-bordered>tbody>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
.panel>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-bordered>tbody>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
.panel>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
    border-bottom: 0;
}

.panel>.table-responsive {
    margin-bottom: 0;
    border: 0;
}

.panel-group {
    margin-bottom: 20px;
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}

.panel-group .panel+.panel {
    margin-top: 5px;
}

.panel-group .panel-heading {
    border-bottom: 0;
}

.panel-group .panel-heading+.panel-collapse>.panel-body,
.panel-group .panel-heading+.panel-collapse>.list-group {
    border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
    border-top: 0;
}

.panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom: 1px solid #ddd;
}

.panel-default {
    border-color: #ddd;
}

.panel-default>.panel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ddd;
}

.panel-default>.panel-heading .badge {
    color: #f5f5f5;
    background-color: #333333;
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ddd;
}

.panel-primary {
    border-color: #337ab7;
}

.panel-primary>.panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #337ab7;
}

.panel-primary>.panel-heading .badge {
    color: #337ab7;
    background-color: #fff;
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #337ab7;
}

.panel-success {
    border-color: #d6e9c6;
}

.panel-success>.panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #d6e9c6;
}

.panel-success>.panel-heading .badge {
    color: #dff0d8;
    background-color: #3c763d;
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #d6e9c6;
}

.panel-info {
    border-color: #bce8f1;
}

.panel-info>.panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #bce8f1;
}

.panel-info>.panel-heading .badge {
    color: #d9edf7;
    background-color: #31708f;
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #bce8f1;
}

.panel-warning {
    border-color: #faebcc;
}

.panel-warning>.panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #faebcc;
}

.panel-warning>.panel-heading .badge {
    color: #fcf8e3;
    background-color: #8a6d3b;
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #faebcc;
}

.panel-danger {
    border-color: #ebccd1;
}

.panel-danger>.panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ebccd1;
}

.panel-danger>.panel-heading .badge {
    color: #f2dede;
    background-color: #a94442;
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ebccd1;
}

.btn-group-vertical>.btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
    clear: both;
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " ";
}

/* panel css ends here */

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
    min-height: 27px;
}

.form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 768px) {
    .form-horizontal .control-label {
        padding-top: 7px;
        margin-bottom: 0;
        text-align: right;
    }
}

.form-horizontal .has-feedback .form-control-feedback {
    right: 15px;
}

@media (min-width: 768px) {
    .form-horizontal .form-group-lg .control-label {
        padding-top: 11px;
        font-size: 18px;
    }
}

@media (min-width: 768px) {
    .form-horizontal .form-group-sm .control-label {
        padding-top: 6px;
        font-size: 12px;
    }
}

.form-control {
    font-size: 14px;
}

/* form inline starts here */
@media (min-width: 768px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-static {
        display: inline-block;
    }

    .form-inline .input-group {
        display: inline-table;
        vertical-align: middle;
    }

    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
        width: auto;
    }

    .form-inline .input-group>.form-control {
        width: 100%;
    }

    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .radio,
    .form-inline .checkbox {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .radio label,
    .form-inline .checkbox label {
        padding-left: 0;
    }

    .form-inline .radio input[type="radio"],
    .form-inline .checkbox input[type="checkbox"] {
        position: relative;
        margin-left: 0;
    }

    .form-inline .has-feedback .form-control-feedback {
        top: 0;
    }
}

/* form inline ends here */

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
}

.well-lg {
    padding: 24px;
    border-radius: 6px;
}

.well-sm {
    padding: 9px;
    border-radius: 3px;
}

.bg-primary {
    color: #fff;
    background-color: #337ab7 !important;
}

.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
    background-color: #d9edf7;
}

.collapse.in {
    display: block;
}

.collapse {
    display: none;
}

/* bootstrap visibility css starts here */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }

    table.visible-xs {
        display: table !important;
    }

    tr.visible-xs {
        display: table-row !important;
    }

    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }

    table.visible-sm {
        display: table !important;
    }

    tr.visible-sm {
        display: table-row !important;
    }

    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-block {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline {
        display: inline !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }

    table.visible-md {
        display: table !important;
    }

    tr.visible-md {
        display: table-row !important;
    }

    th.visible-md,
    td.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-block {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline {
        display: inline !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }

    table.visible-lg {
        display: table !important;
    }

    tr.visible-lg {
        display: table-row !important;
    }

    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-block {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline {
        display: inline !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}

.visible-print {
    display: none !important;
}

@media print {
    .visible-print {
        display: block !important;
    }

    table.visible-print {
        display: table !important;
    }

    tr.visible-print {
        display: table-row !important;
    }

    th.visible-print,
    td.visible-print {
        display: table-cell !important;
    }
}

.visible-print-block {
    display: none !important;
}

@media print {
    .visible-print-block {
        display: block !important;
    }
}

.visible-print-inline {
    display: none !important;
}

@media print {
    .visible-print-inline {
        display: inline !important;
    }
}

.visible-print-inline-block {
    display: none !important;
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}

/* bootstrap visibility css ends here */


.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

thead.light-blue tr th,
thead.light-blue tr td,
tr.light-blue th,
tr.light-blue td {
    color: #fff;
    background-color: #074474 !important;
}

tr.bg-primary th,
tr.bg-primary td {
    color: #fff;
    background-color: #337ab7;
}

tr.header-blue th,
tr.header-blue td {
    color: #fff;
    background-color: #074474;
}

tr.inlumon-table-header-row th,
tr.inlumon-table-header-row td {
    background-color: #074474 !important;
    color: white;
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    height: auto;
    margin-bottom: 0px !important;
}  */

.checkbox-inline,
.radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

.personal-fieldset legend {
    float: unset;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1;
}

.col-xs-offset-12 {
    margin-left: 100%;
}

.col-xs-offset-11 {
    margin-left: 91.66666667%;
}

.col-xs-offset-10 {
    margin-left: 83.33333333%;
}

.col-xs-offset-9 {
    margin-left: 75%;
}

.col-xs-offset-8 {
    margin-left: 66.66666667%;
}

.col-xs-offset-7 {
    margin-left: 58.33333333%;
}

.col-xs-offset-6 {
    margin-left: 50%;
}

.col-xs-offset-5 {
    margin-left: 41.66666667%;
}

.col-xs-offset-4 {
    margin-left: 33.33333333%;
}

.col-xs-offset-3 {
    margin-left: 25%;
}

.col-xs-offset-2 {
    margin-left: 16.66666667%;
}

.col-xs-offset-1 {
    margin-left: 8.33333333%;
}

.col-xs-offset-0 {
    margin-left: 0%;
}

.col-offset-12 {
    margin-left: 100%;
}

.col-offset-11 {
    margin-left: 91.66666667%;
}

.col-offset-10 {
    margin-left: 83.33333333%;
}

.col-offset-9 {
    margin-left: 75%;
}

.col-offset-8 {
    margin-left: 66.66666667%;
}

.col-offset-7 {
    margin-left: 58.33333333%;
}

.col-offset-6 {
    margin-left: 50%;
}

.col-offset-5 {
    margin-left: 41.66666667%;
}

.col-offset-4 {
    margin-left: 33.33333333%;
}

.col-offset-3 {
    margin-left: 25%;
}

.col-offset-2 {
    margin-left: 16.66666667%;
}

.col-offset-1 {
    margin-left: 8.33333333%;
}

.col-offset-0 {
    margin-left: 0%;
}

@media (min-width: 768px) {
    .col-sm-offset-12 {
        margin-left: 100%;
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-sm-offset-0 {
        margin-left: 0%;
    }
}

@media (min-width: 992px) {
    .col-md-offset-12 {
        margin-left: 100%;
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-md-offset-0 {
        margin-left: 0%;
    }
}

@media (min-width: 1200px) {
    .col-lg-offset-12 {
        margin-left: 100%;
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-lg-offset-0 {
        margin-left: 0%;
    }
}

/* glyphicon css starts here */
@font-face {
    font-family: "Glyphicons Halflings";
    src: url("../../fonts/glyphicons-halflings-regular.eot");
    src: url("../../fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("../../fonts/glyphicons-halflings-regular.woff") format("woff"), url("../../fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("../../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk:before {
    content: "\002a";
}

.glyphicon-plus:before {
    content: "\002b";
}

.glyphicon-euro:before,
.glyphicon-eur:before {
    content: "\20ac";
}

.glyphicon-minus:before {
    content: "\2212";
}

.glyphicon-cloud:before {
    content: "\2601";
}

.glyphicon-envelope:before {
    content: "\2709";
}

.glyphicon-pencil:before {
    content: "\270f";
}

.glyphicon-glass:before {
    content: "\e001";
}

.glyphicon-music:before {
    content: "\e002";
}

.glyphicon-search:before {
    content: "\e003";
}

.glyphicon-heart:before {
    content: "\e005";
}

.glyphicon-star:before {
    content: "\e006";
}

.glyphicon-star-empty:before {
    content: "\e007";
}

.glyphicon-user:before {
    content: "\e008";
}

.glyphicon-film:before {
    content: "\e009";
}

.glyphicon-th-large:before {
    content: "\e010";
}

.glyphicon-th:before {
    content: "\e011";
}

.glyphicon-th-list:before {
    content: "\e012";
}

.glyphicon-ok:before {
    content: "\e013";
}

.glyphicon-remove:before {
    content: "\e014";
}

.glyphicon-zoom-in:before {
    content: "\e015";
}

.glyphicon-zoom-out:before {
    content: "\e016";
}

.glyphicon-off:before {
    content: "\e017";
}

.glyphicon-signal:before {
    content: "\e018";
}

.glyphicon-cog:before {
    content: "\e019";
}

.glyphicon-trash:before {
    content: "\e020";
}

.glyphicon-home:before {
    content: "\e021";
}

.glyphicon-file:before {
    content: "\e022";
}

.glyphicon-time:before {
    content: "\e023";
}

.glyphicon-road:before {
    content: "\e024";
}

.glyphicon-download-alt:before {
    content: "\e025";
}

.glyphicon-download:before {
    content: "\e026";
}

.glyphicon-upload:before {
    content: "\e027";
}

.glyphicon-inbox:before {
    content: "\e028";
}

.glyphicon-play-circle:before {
    content: "\e029";
}

.glyphicon-repeat:before {
    content: "\e030";
}

.glyphicon-refresh:before {
    content: "\e031";
}

.glyphicon-list-alt:before {
    content: "\e032";
}

.glyphicon-lock:before {
    content: "\e033";
}

.glyphicon-flag:before {
    content: "\e034";
}

.glyphicon-headphones:before {
    content: "\e035";
}

.glyphicon-volume-off:before {
    content: "\e036";
}

.glyphicon-volume-down:before {
    content: "\e037";
}

.glyphicon-volume-up:before {
    content: "\e038";
}

.glyphicon-qrcode:before {
    content: "\e039";
}

.glyphicon-barcode:before {
    content: "\e040";
}

.glyphicon-tag:before {
    content: "\e041";
}

.glyphicon-tags:before {
    content: "\e042";
}

.glyphicon-book:before {
    content: "\e043";
}

.glyphicon-bookmark:before {
    content: "\e044";
}

.glyphicon-print:before {
    content: "\e045";
}

.glyphicon-camera:before {
    content: "\e046";
}

.glyphicon-font:before {
    content: "\e047";
}

.glyphicon-bold:before {
    content: "\e048";
}

.glyphicon-italic:before {
    content: "\e049";
}

.glyphicon-text-height:before {
    content: "\e050";
}

.glyphicon-text-width:before {
    content: "\e051";
}

.glyphicon-align-left:before {
    content: "\e052";
}

.glyphicon-align-center:before {
    content: "\e053";
}

.glyphicon-align-right:before {
    content: "\e054";
}

.glyphicon-align-justify:before {
    content: "\e055";
}

.glyphicon-list:before {
    content: "\e056";
}

.glyphicon-indent-left:before {
    content: "\e057";
}

.glyphicon-indent-right:before {
    content: "\e058";
}

.glyphicon-facetime-video:before {
    content: "\e059";
}

.glyphicon-picture:before {
    content: "\e060";
}

.glyphicon-map-marker:before {
    content: "\e062";
}

.glyphicon-adjust:before {
    content: "\e063";
}

.glyphicon-tint:before {
    content: "\e064";
}

.glyphicon-edit:before {
    content: "\e065";
}

.glyphicon-share:before {
    content: "\e066";
}

.glyphicon-check:before {
    content: "\e067";
}

.glyphicon-move:before {
    content: "\e068";
}

.glyphicon-step-backward:before {
    content: "\e069";
}

.glyphicon-fast-backward:before {
    content: "\e070";
}

.glyphicon-backward:before {
    content: "\e071";
}

.glyphicon-play:before {
    content: "\e072";
}

.glyphicon-pause:before {
    content: "\e073";
}

.glyphicon-stop:before {
    content: "\e074";
}

.glyphicon-forward:before {
    content: "\e075";
}

.glyphicon-fast-forward:before {
    content: "\e076";
}

.glyphicon-step-forward:before {
    content: "\e077";
}

.glyphicon-eject:before {
    content: "\e078";
}

.glyphicon-chevron-left:before {
    content: "\e079";
}

.glyphicon-chevron-right:before {
    content: "\e080";
}

.glyphicon-plus-sign:before {
    content: "\e081";
}

.glyphicon-minus-sign:before {
    content: "\e082";
}

.glyphicon-remove-sign:before {
    content: "\e083";
}

.glyphicon-ok-sign:before {
    content: "\e084";
}

.glyphicon-question-sign:before {
    content: "\e085";
}

.glyphicon-info-sign:before {
    content: "\e086";
}

.glyphicon-screenshot:before {
    content: "\e087";
}

.glyphicon-remove-circle:before {
    content: "\e088";
}

.glyphicon-ok-circle:before {
    content: "\e089";
}

.glyphicon-ban-circle:before {
    content: "\e090";
}

.glyphicon-arrow-left:before {
    content: "\e091";
}

.glyphicon-arrow-right:before {
    content: "\e092";
}

.glyphicon-arrow-up:before {
    content: "\e093";
}

.glyphicon-arrow-down:before {
    content: "\e094";
}

.glyphicon-share-alt:before {
    content: "\e095";
}

.glyphicon-resize-full:before {
    content: "\e096";
}

.glyphicon-resize-small:before {
    content: "\e097";
}

.glyphicon-exclamation-sign:before {
    content: "\e101";
}

.glyphicon-gift:before {
    content: "\e102";
}

.glyphicon-leaf:before {
    content: "\e103";
}

.glyphicon-fire:before {
    content: "\e104";
}

.glyphicon-eye-open:before {
    content: "\e105";
}

.glyphicon-eye-close:before {
    content: "\e106";
}

.glyphicon-warning-sign:before {
    content: "\e107";
}

.glyphicon-plane:before {
    content: "\e108";
}

.glyphicon-calendar:before {
    content: "\e109";
}

.glyphicon-random:before {
    content: "\e110";
}

.glyphicon-comment:before {
    content: "\e111";
}

.glyphicon-magnet:before {
    content: "\e112";
}

.glyphicon-chevron-up:before {
    content: "\e113";
}

.glyphicon-chevron-down:before {
    content: "\e114";
}

.glyphicon-retweet:before {
    content: "\e115";
}

.glyphicon-shopping-cart:before {
    content: "\e116";
}

.glyphicon-folder-close:before {
    content: "\e117";
}

.glyphicon-folder-open:before {
    content: "\e118";
}

.glyphicon-resize-vertical:before {
    content: "\e119";
}

.glyphicon-resize-horizontal:before {
    content: "\e120";
}

.glyphicon-hdd:before {
    content: "\e121";
}

.glyphicon-bullhorn:before {
    content: "\e122";
}

.glyphicon-bell:before {
    content: "\e123";
}

.glyphicon-certificate:before {
    content: "\e124";
}

.glyphicon-thumbs-up:before {
    content: "\e125";
}

.glyphicon-thumbs-down:before {
    content: "\e126";
}

.glyphicon-hand-right:before {
    content: "\e127";
}

.glyphicon-hand-left:before {
    content: "\e128";
}

.glyphicon-hand-up:before {
    content: "\e129";
}

.glyphicon-hand-down:before {
    content: "\e130";
}

.glyphicon-circle-arrow-right:before {
    content: "\e131";
}

.glyphicon-circle-arrow-left:before {
    content: "\e132";
}

.glyphicon-circle-arrow-up:before {
    content: "\e133";
}

.glyphicon-circle-arrow-down:before {
    content: "\e134";
}

.glyphicon-globe:before {
    content: "\e135";
}

.glyphicon-wrench:before {
    content: "\e136";
}

.glyphicon-tasks:before {
    content: "\e137";
}

.glyphicon-filter:before {
    content: "\e138";
}

.glyphicon-briefcase:before {
    content: "\e139";
}

.glyphicon-fullscreen:before {
    content: "\e140";
}

.glyphicon-dashboard:before {
    content: "\e141";
}

.glyphicon-paperclip:before {
    content: "\e142";
}

.glyphicon-heart-empty:before {
    content: "\e143";
}

.glyphicon-link:before {
    content: "\e144";
}

.glyphicon-phone:before {
    content: "\e145";
}

.glyphicon-pushpin:before {
    content: "\e146";
}

.glyphicon-usd:before {
    content: "\e148";
}

.glyphicon-gbp:before {
    content: "\e149";
}

.glyphicon-sort:before {
    content: "\e150";
}

.glyphicon-sort-by-alphabet:before {
    content: "\e151";
}

.glyphicon-sort-by-alphabet-alt:before {
    content: "\e152";
}

.glyphicon-sort-by-order:before {
    content: "\e153";
}

.glyphicon-sort-by-order-alt:before {
    content: "\e154";
}

.glyphicon-sort-by-attributes:before {
    content: "\e155";
}

.glyphicon-sort-by-attributes-alt:before {
    content: "\e156";
}

.glyphicon-unchecked:before {
    content: "\e157";
}

.glyphicon-expand:before {
    content: "\e158";
}

.glyphicon-collapse-down:before {
    content: "\e159";
}

.glyphicon-collapse-up:before {
    content: "\e160";
}

.glyphicon-log-in:before {
    content: "\e161";
}

.glyphicon-flash:before {
    content: "\e162";
}

.glyphicon-log-out:before {
    content: "\e163";
}

.glyphicon-new-window:before {
    content: "\e164";
}

.glyphicon-record:before {
    content: "\e165";
}

.glyphicon-save:before {
    content: "\e166";
}

.glyphicon-open:before {
    content: "\e167";
}

.glyphicon-saved:before {
    content: "\e168";
}

.glyphicon-import:before {
    content: "\e169";
}

.glyphicon-export:before {
    content: "\e170";
}

.glyphicon-send:before {
    content: "\e171";
}

.glyphicon-floppy-disk:before {
    content: "\e172";
}

.glyphicon-floppy-saved:before {
    content: "\e173";
}

.glyphicon-floppy-remove:before {
    content: "\e174";
}

.glyphicon-floppy-save:before {
    content: "\e175";
}

.glyphicon-floppy-open:before {
    content: "\e176";
}

.glyphicon-credit-card:before {
    content: "\e177";
}

.glyphicon-transfer:before {
    content: "\e178";
}

.glyphicon-cutlery:before {
    content: "\e179";
}

.glyphicon-header:before {
    content: "\e180";
}

.glyphicon-compressed:before {
    content: "\e181";
}

.glyphicon-earphone:before {
    content: "\e182";
}

.glyphicon-phone-alt:before {
    content: "\e183";
}

.glyphicon-tower:before {
    content: "\e184";
}

.glyphicon-stats:before {
    content: "\e185";
}

.glyphicon-sd-video:before {
    content: "\e186";
}

.glyphicon-hd-video:before {
    content: "\e187";
}

.glyphicon-subtitles:before {
    content: "\e188";
}

.glyphicon-sound-stereo:before {
    content: "\e189";
}

.glyphicon-sound-dolby:before {
    content: "\e190";
}

.glyphicon-sound-5-1:before {
    content: "\e191";
}

.glyphicon-sound-6-1:before {
    content: "\e192";
}

.glyphicon-sound-7-1:before {
    content: "\e193";
}

.glyphicon-copyright-mark:before {
    content: "\e194";
}

.glyphicon-registration-mark:before {
    content: "\e195";
}

.glyphicon-cloud-download:before {
    content: "\e197";
}

.glyphicon-cloud-upload:before {
    content: "\e198";
}

.glyphicon-tree-conifer:before {
    content: "\e199";
}

.glyphicon-tree-deciduous:before {
    content: "\e200";
}

.glyphicon-cd:before {
    content: "\e201";
}

.glyphicon-save-file:before {
    content: "\e202";
}

.glyphicon-open-file:before {
    content: "\e203";
}

.glyphicon-level-up:before {
    content: "\e204";
}

.glyphicon-copy:before {
    content: "\e205";
}

.glyphicon-paste:before {
    content: "\e206";
}

.glyphicon-alert:before {
    content: "\e209";
}

.glyphicon-equalizer:before {
    content: "\e210";
}

.glyphicon-king:before {
    content: "\e211";
}

.glyphicon-queen:before {
    content: "\e212";
}

.glyphicon-pawn:before {
    content: "\e213";
}

.glyphicon-bishop:before {
    content: "\e214";
}

.glyphicon-knight:before {
    content: "\e215";
}

.glyphicon-baby-formula:before {
    content: "\e216";
}

.glyphicon-tent:before {
    content: "\26fa";
}

.glyphicon-blackboard:before {
    content: "\e218";
}

.glyphicon-bed:before {
    content: "\e219";
}

.glyphicon-apple:before {
    content: "\f8ff";
}

.glyphicon-erase:before {
    content: "\e221";
}

.glyphicon-hourglass:before {
    content: "\231b";
}

.glyphicon-lamp:before {
    content: "\e223";
}

.glyphicon-duplicate:before {
    content: "\e224";
}

.glyphicon-piggy-bank:before {
    content: "\e225";
}

.glyphicon-scissors:before {
    content: "\e226";
}

.glyphicon-bitcoin:before {
    content: "\e227";
}

.glyphicon-btc:before {
    content: "\e227";
}

.glyphicon-xbt:before {
    content: "\e227";
}

.glyphicon-yen:before {
    content: "\00a5";
}

.glyphicon-jpy:before {
    content: "\00a5";
}

.glyphicon-ruble:before {
    content: "\20bd";
}

.glyphicon-rub:before {
    content: "\20bd";
}

.glyphicon-scale:before {
    content: "\e230";
}

.glyphicon-ice-lolly:before {
    content: "\e231";
}

.glyphicon-ice-lolly-tasted:before {
    content: "\e232";
}

.glyphicon-education:before {
    content: "\e233";
}

.glyphicon-option-horizontal:before {
    content: "\e234";
}

.glyphicon-option-vertical:before {
    content: "\e235";
}

.glyphicon-menu-hamburger:before {
    content: "\e236";
}

.glyphicon-modal-window:before {
    content: "\e237";
}

.glyphicon-oil:before {
    content: "\e238";
}

.glyphicon-grain:before {
    content: "\e239";
}

.glyphicon-sunglasses:before {
    content: "\e240";
}

.glyphicon-text-size:before {
    content: "\e241";
}

.glyphicon-text-color:before {
    content: "\e242";
}

.glyphicon-text-background:before {
    content: "\e243";
}

.glyphicon-object-align-top:before {
    content: "\e244";
}

.glyphicon-object-align-bottom:before {
    content: "\e245";
}

.glyphicon-object-align-horizontal:before {
    content: "\e246";
}

.glyphicon-object-align-left:before {
    content: "\e247";
}

.glyphicon-object-align-vertical:before {
    content: "\e248";
}

.glyphicon-object-align-right:before {
    content: "\e249";
}

.glyphicon-triangle-right:before {
    content: "\e250";
}

.glyphicon-triangle-left:before {
    content: "\e251";
}

.glyphicon-triangle-bottom:before {
    content: "\e252";
}

.glyphicon-triangle-top:before {
    content: "\e253";
}

.glyphicon-console:before {
    content: "\e254";
}

.glyphicon-superscript:before {
    content: "\e255";
}

.glyphicon-subscript:before {
    content: "\e256";
}

.glyphicon-menu-left:before {
    content: "\e257";
}

.glyphicon-menu-right:before {
    content: "\e258";
}

.glyphicon-menu-down:before {
    content: "\e259";
}

.glyphicon-menu-up:before {
    content: "\e260";
}

/* glyphicon css ends here */

.form-control {
    -moz-appearance: auto;
    appearance: auto;
}

.btn.btn-success:disabled {
    background-color: #214A21;
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.btn-group-xs>.btn,
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
}

.btn-warning {
    background: #5E3A08;
}

.btn-warning.btn.disabled,
.btn-warning.btn:disabled {
    background: #5E3A08;
    color: white;
}

.nav-stacked {
    display: block;
}

.nav-stacked>li {
    float: none;
}

/* 
.input-group[class*=col-] {
    float: none;
    padding-right: 0;
    padding-left: 0;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
} */

.input-group {
    width: auto;
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    margin-bottom: 0;
}

.input-group[class*=col-] {
    float: none;
    padding-right: 0;
    padding-left: 0;
}

@media (min-width: 768px) {
    .input-group.col-md-5 {
        width: 41.66666667%;
    }
}

.pagination {
    margin: 20px 0;
    border-radius: 4px;
}

.inlumon-table-pager {
    display: flex;
    justify-content: center;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}

.flex-align-center {
    display: flex;
    justify-content: center;
}

.clear-both {
    display: block;
}

.clear-both::after,
.clear-both::before {
    clear: both;
}

.mob-pages {
    display: flex;
    justify-content: center;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.display-inline-full {
    display: inline-block;
    width: 100%;
}

@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto;
    }
}

.text-right {
    text-align: right !important;
}

.modal {
    z-index: 1055;
}

.modal.fade {
    opacity: 1;
}

.modal-header {
    display: block;
}

.modal-dialog {
    transform: translate(0, 0) !important;
}

.modal-dialog .modal-content .modal-header .close {
    background: none;
}

.modal {
    --bs-modal-width: unset;
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5;
}

.gridUpperDiv .header-right>* {
    width: auto;
}

.panel-blue {
    border-color: #337ab7;
}

.panel-blue>.panel-heading {
    color: #fff;
    background-color: #063464;
    border-color: #337ab7;
}

tr.bg-blue th,
tr.bg-blue td {
    color: #fff;
    background-color: #074474;
}

.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border: 1px solid #ddd;
}

.panel>.table-bordered,
.panel>.table-responsive>.table-bordered {
    border: 0;
}

.panel .table-bordered tr {
    border-width: 0;
}

/* typeahead css starts here */
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
    outline: 0;
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

/* typeahead css ends here */

/* multiselect css starts here */
.multi-select-dropdown-menu {
    text-align: left;
    padding: 10px;
    font-size: 14px;
    max-height: 200px;
    overflow-y: auto;
}

.multi-select-dropdown-menu .dropdown-item>button {
    height: 27px;
    width: 100%;
    background-color: rgb(240, 240, 240);
    color: #000;
}

/* multiselect css ends here */

.bg-danger {
    background-color: #f2dede !important;
}

.login-form-section .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.login-form-section .input-group label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.login-form-section .input-group .form-control {
    display: table-cell;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.login-form-section .input-group.col-sm-12 {
    width: 100%;
}

.login-form-section .input-group .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form-section .input-group .input-group-addon,
.login-form-section .input-group .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}

.login-form-section .input-group .input-group .form-control,
.login-form-section .input-group .input-group-addon,
.input-group-btn {
    display: table-cell;
}

/* Popover css starts here */
.popover.fade.in {
    opacity: 1;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    line-break: auto;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    font-size: 14px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.popover.top {
    margin-top: -10px;
}

.popover.right {
    margin-left: 10px;
}

.popover.bottom {
    margin-top: 10px;
}

.popover.left {
    margin-left: -10px;
}

.popover>.arrow {
    border-width: 11px;
}

.popover>.arrow,
.popover>.arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.popover>.arrow:after {
    content: "";
    border-width: 10px;
}

.popover.top>.arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;
}

.popover.top>.arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0;
}

.popover.right>.arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999999;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-width: 0;
}

.popover.right>.arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #fff;
    border-left-width: 0;
}

.popover.bottom>.arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.popover.bottom>.arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff;
}

.popover.left>.arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999999;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.left>.arrow:after {
    right: 1px;
    bottom: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #fff;
}

.popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}

.popover-content {
    padding: 9px 14px;
}

/* Popover css ends here */