.date-time-picker {
    width: 100% !important;
    height: 100% !important;
    background-color: white;
    padding: 0px !important;
}

.date-time-picker .date-time-picker-toggle {
    position: relative;
    height: 26px;
    /* border: 1px solid lightgray; */
    line-height: 18px;
    background-color: white;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: 100%;
    padding: 2px 10px 2px 6px;
    font-size: 14px;
}