

.btn-blue {
	background-color: #6acaff;
	color:#fff;
	border-color: #4c7889;
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue.focus,
.btn-blue:active,
.btn-blue.active,
.open > .dropdown-toggle.btn-blue {
	border-color: #33505b;
}

.btn-blue:active,
.btn-blue.active,
.open > .dropdown-toggle.btn-blue {
	background-image: none;
}

.btn-blue.disabled,
.btn-blue[disabled],
fieldset[disabled] .btn-blue,
.btn-blue.disabled:hover,
.btn-blue[disabled]:hover,
fieldset[disabled] .btn-blue:hover,
.btn-blue.disabled:focus,
.btn-blue[disabled]:focus,
fieldset[disabled] .btn-blue:focus,
.btn-blue.disabled.focus,
.btn-blue[disabled].focus,
fieldset[disabled] .btn-blue.focus,
.btn-blue.disabled:active,
.btn-blue[disabled]:active,
fieldset[disabled] .btn-blue:active,
.btn-blue.disabled.active,
.btn-blue[disabled].active,
fieldset[disabled] .btn-blue.active {
	border-color: #4c7889;
}


[ta-button] {
	height: 44px;
	margin-bottom: 5px;
}

.red{ color: red; }