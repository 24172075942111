.inlumon-table {
    border-radius: 5px;
    border: 1px solid lightgray;
}

.inlumon-table-scroller {
    width: 100%;
    overflow: auto;
}

.inlumon-table-menu {
    background: #eee none repeat scroll 0 0;
    border-radius: 5px 5px 0 0;
    margin-bottom: -3px;
    /* overflow: hidden; */
    padding: 4px;
    width: 100%;
    min-height: 30px;
}

.inlumon-table-before-row,
.inlumon-table-after-row {
    width: 100%;
    background-color: white;
    padding: 0px !important;
}

.inlumon-table-pager {
    text-align: center;
    border-top: 1px solid lightgray;
}

.inlumon-table-header-row,
.inlumon-table-data-row {
    border-bottom: 1px solid lightgray;
    list-style-type: none;
}

.inlumon-table-header-row {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    left: 0px;
    background-color: #074474 !important;
    color: white;
    min-height: 30px;
}

/* .inlumon-table-data-row:nth-child(even) {
    background-color: lightgray;
} */

.inlumon-table-header-cell,
.inlumon-table-data-cell {
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.inlumon-table-data-cell:not(:first-child) {
    border-left: 1px solid lightgray;
}

.inlumon-table-header-cell-content,
.inlumon-table-data-cell-content {
    padding-left: 5px;
    /* width: 100%; */
    perspective: 1px;
}

.inlumon-table-header-cell a,
.inlumon-table-header-cell a:visited {
    color: white;
}