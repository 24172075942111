/*!
 * validation.css for Native Software 
 *
 * Copyright 2014 v2.0.0
 * Created By Amit Parekh
 * Created Date 10/07/2014
 * Modify By Amit Parekh
 * Modify Date 18/09/2014
 */

/*------------------[ Label Classes ]------------------*/
.required-label:before {
    content: "*";
    color: #ff0000;
    margin-left: 2px;
    /* font-size: 15px; */
    position: relative;
}

.required-type:before {
    content: "*";
    color: #ff0000;
    margin-left: 2px;
    /* font-size: 15px; */
    position: relative;
}

/*------------------[ Input Textbox Border Format Classes ]------------------*/
.required-text {
    /*border: 1px solid #ff0000 !important;*/
    background-color: #FFEBEB !important;
}

.required-text:hover~.nat-err-msg {
    display: block !important;
}

/*------------------[ Span Tag Format Classes ]------------------*/
.nat-err-msg {
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: 2px center;
    border: 1px solid #8c8c8c;
    right: 0;
    padding: 0px 5px;
    position: absolute;
    top: 60px;
    z-index: 1000;
    font-size: 12px;
    color: #FF0000;
    display: none;
    line-height: 24px;
}

.nat-err-msg-ng {
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: 2px center;
    border: 1px solid #8c8c8c;
    right: 0;
    padding: 0px 5px;
    position: absolute;
    top: 41px;
    z-index: 1000;
    font-size: 12px;
    color: #FF0000;
    line-height: 24px;
}

.nat-err-msg:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #ff0000;
    border-width: 8px;
    margin-left: -36px;
}

.nat-err-msg-ng:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #ff0000;
    border-width: 8px;
    margin-left: -36px;
}

.nat-err-ng {
    font-size: 12px;
    color: #FF0000;
    line-height: 15px;
    float: right !important;
}

.notok {
    background: url(/app/content/images/error.gif) no-repeat left;
    height: 16px;
    float: inherit;
    width: 20px;
    display: inline-block;
}

/*------------------[ Input Tag Classes ]------------------*/
.nat-required {
    /* Apply your css */
}

.nat-alphabet {
    /* Apply your css */
}

.nat-number {
    /* Apply your css */
}

.nat-alphanumeric {
    /* Apply your css */
}

.nat-regex {
    /* Apply your css */
}

.nat-string {
    /* Apply your css */
}

.nat-email {
    /* Apply your css */
}

.nat-url {
    /* Apply your css */
}

.nat-compare {
    /* Apply your css */
}

.nat-unique {
    /* Apply your css */
}

.nat-custom {
    /* Apply your css */
}

/*------------------[ Error Span Classes ]------------------*/
.requirederrormessage {
    /* Apply your css */
}

.valueerrormessage {
    /* Apply your css */
}

.valuelengtherrormessage {
    /* Apply your css */
}

.valuelimiterrormessage {
    /* Apply your css */
}

.compareerrormessage {
    /* Apply your css */
}

.uniqueerrormessage {
    /* Apply your css */
}

.customerrormessage {
    /* Apply your css */
}

.validation-summary,
.validation-summary-ng {
    background-color: #F2DEDE;
    color: #A94442;
    border: 1px solid #A94442 !important;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    font-size: small;
    display: none;
}

.validation-summary-ng {
    display: block;
}

.validation-summary a,
.validation-summary-ng a {
    position: absolute;
    top: 0;
    right: 0;
    margin: 3px 5px;
    text-decoration: none;
    color: #737272;
    cursor: pointer;
}

.validation-summary li,
.validation-summary-ng li {
    list-style: disc !important;
    margin-left: 20px;
    float: none !important;
}

/*.validation-summary:after {
        content: "\f00d ";
        font-family: 'FontAwesome';
        margin: 3px 6px;
        vertical-align: top;
        color: #737272;
        right: 0;
        position: absolute;
        top: 0;
        cursor: pointer;
    }*/

.status-message,
.status-message-ng {
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    font-size: small;
    display: none;
}

.status-message-ng {
    display: block;
}

.status-message a,
.status-message-ng a {
    position: absolute;
    top: 0;
    right: 0;
    margin: 3px 5px;
    text-decoration: none;
    color: #737272;
    cursor: pointer;
}

.status-message.error,
.status-message-ng.error {
    border: 1px solid #A94442 !important;
    background-color: #F2DEDE;
    color: #A94442;
}

.status-message.error:before,
.status-message-ng.error:before {
    content: "\f00d ";
    font-family: 'FontAwesome';
    margin: 6px;
    color: #A94442;
    font-size: large;
}

.status-message.success,
.status-message-ng.success {
    border: 1px solid #47802C !important;
    background-color: #CCEBC9;
    color: #47802C;
}

.status-message.success:before,
.status-message-ng.success:before {
    content: "\f00c ";
    font-family: 'FontAwesome';
    margin: 6px;
    color: #47802C;
    font-size: large;
}

.status-message.warning,
.status-message-ng.warning {
    border: 1px solid #A94442 !important;
    background-color: #F2DEDE;
    color: #A94442;
}

.status-message.warning:before,
.status-message-ng.warning:before {
    content: "\f071 ";
    font-family: 'FontAwesome';
    margin: 6px;
    color: #A94442;
    font-size: large;
}

.prefered-error .nat-err-msg-ng {
    right: inherit;
    top: 42px;
}

.required-content>span:after {
    content: "*";
    color: #ff0000;
    margin-left: 2px;
    /* font-size: 15px; */
    position: relative;
}

.required-content span:first-of-type p {
    display: inline-block;
}