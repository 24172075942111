.droppable {
    border: 2px dashed #074474;
    min-height: 60px;
}

.highlight-dragger {
    border: 4px dashed #5cbf5c !important;
}

.bg-white {
    background-color: white;
}

.draggable {
    position: fixed;
    background-color: white;
    z-index: 100
}

.tabs-unplugged {
    width: 250px !important;
}

.panel-heading-tabs {
    display: inline-block;
    width: 100%;
}

.individual-navigation-tabs {
    padding: 5px;
}

.tabs-unplugged .individual-navigation-tabs {
    display: none;
}

.individual-navigation-tabs-list {
    display: none;
}

.tabs-unplugged .individual-navigation-tabs-list {
    display: block;
    height: 500px;
    overflow-y: auto;
}

.tabs-unplugged .nav-tabs>li {
    width: 100%;
}

.tabs-plugged .nav-tabs>li {
    width: auto;
}

.collapse-panel-header {
    padding: 4px;
    font-size: 13px;
    font-weight: 400;
}

.collapse-panel-header-block {
    font-family: Verdana;
    padding: 5px 12px;
    padding-right: 35px;
    position: relative;
}

.collapse-panel-header.bg-primary {
    border: 1px solid #296192;
    -webkit-box-shadow: inset 0px 1px 2px 0px #337ab7, 0px 1px 2px 0px #296192;
    box-shadow: inset 0px 1px 2px 0px #337ab7, 0px 1px 2px 0px #296192;
}

.collapse-panel-header.bg-primary a {
    color: white;
}

.collapse-panel-header.bg-highlight {
    border: 1px solid #cecece;
    -webkit-box-shadow: inset 0px 1px 2px 0px #fff, 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 1px 2px 0px #fff, 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.collapse-panel-header.bg-highlight a {
    color: #1884b5;
}

.collapse-panel-header.bg-lightblue {
    border: 1px solid #2e97bf;
    -webkit-box-shadow: inset 0px 1px 2px 0px #3fa3ca, 0px 1px 2px 0px #2c8db3;
    box-shadow: inset 0px 1px 2px 0px #3fa3ca, 0px 1px 2px 0px #2c8db3;
}

.collapse-panel-header.bg-lightblue a {
    color: #fff;
}

.collapse-panel-header.bg-blue a {
    color: #fff;
}

.collapse-panel-header p {
    display: inline-block;
    margin: 0;
}

collapse-panel {
    display: block;
}

.rounded-panel-bottom {
    border: 1px solid #ccc;
    border-radius: 0px 0px 5px 5px;
    position: relative;
    padding: 5px;
    width: 100%;
}

.straight-panel-bottom {
    border-radius: 0px 0px 0px 0px;
}
 
.inl-collapse-panel .dragger-handler{
    margin-top: -6px;
    margin-left: -10px;
}

.inl-collapse-panel .expand-icon,.inl-collapse-panel .collapse-icon{
    position:absolute;
    right: 12px;
    top: 2px;
}