.activity-timer {
    position: fixed;
    border: 1px solid black;
    top: 20px;
    left: 10px;
    padding: 15px;
    background: white !important;
    max-width: 500px;
    z-index: 10000;
    min-width: 200px;
}

.activity-timer-grabber {
    float: left;
    position: relative;
    z-index: 100;
    cursor: grab;
    /* margin: 5px; */
    font-size: 18px;
}

.activity-timer .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}