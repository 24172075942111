.searchBoxhidden {
    overflow: hidden;
    padding: 8px;
    border-radius: 6px 6px;
    background: #fafafa;
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.userHide1 {
    display: none;
}

.userHide {
    display: none;
}

.userHide2 {
    display: none;
}

.close {
    float: right;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border: 0;
    box-shadow: none;
    background: transparent url(../images/close.png) left center no-repeat;
}

.closePrint {
    float: right;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border: 0;
    box-shadow: none;
    background: transparent url(../images/close.png) left center no-repeat;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    /* outline: 0; */
}

.SearchTable p {
    position: relative;
}

.searchBoxhidden p {
    margin: 1px 0px;
}

.searchBoxhidden fieldset input[type="text"],
.searchBoxhidden input[type="password"] {
    display: block;
    height: 23px;
    line-height: 22px;
    margin: 0;
    padding: 0px 0px 0px 6px;
    position: relative;
    z-index: 0;
    font-size: 14px;
    border-color: #ddd;
    -webkit-box-shadow: inset 0 1px 4px 0 rgba(176, 199, 221, .3);
    -moz-box-shadow: inset 0 1px 4px 0 rgba(176, 199, 221, .3);
    box-shadow: inset 0 1px 4px 0 rgba(176, 199, 221, .3);
}

input.password,
input[type="password"],
input.text,
input[type="text"],
select,
textarea,
input[type="tel"],
input[type="email"] {
    border: 1px solid #CCC;
    border-radius: 3px;
    padding: 3px;
}

.brownButton {
    background: #90968D;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+) !important;
    background: -moz-linear-gradient(top, rgba(144, 150, 141, 1) 0%, rgba(97, 102, 95, 1) 100%) !important;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(144, 150, 141, 1)), color-stop(100%, rgba(97, 102, 95, 1))) !important;
    background: -webkit-linear-gradient(top, rgba(144, 150, 141, 1) 0%, rgba(97, 102, 95, 1) 100%) !important;
    background: -o-linear-gradient(top, rgba(144, 150, 141, 1) 0%, rgba(97, 102, 95, 1) 100%) !important;
    background: -ms-linear-gradient(top, rgba(144, 150, 141, 1) 0%, rgba(97, 102, 95, 1) 100%) !important;
    background: linear-gradient(to bottom, rgba(144, 150, 141, 1) 0%, rgba(97, 102, 95, 1) 100%) !important;
    text-shadow: none !important;
    border-color: #AAA !important;
    padding: 0 20px !important;
    height: 24px !important;
    line-height: 18px !important;
    text-align: center !important;
    font-size: 11px !important;
    cursor: pointer;
}

.btn,
.secondary,
input[type="button"].primary,
input[type="submit"].primary,
.inputButton.secondary {
    /*display: inline-block;
    padding: 8px 15px;
    margin: 0;
    font: normal normal bold 14px/14px Arial, sans-serif;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    text-decoration: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.3), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1), 0px 1px 0px rgba(255, 255, 255, .7);
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.3), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1), 0px 1px 0px rgba(255, 255, 255, .7);
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.3), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1), 0px 1px 0px rgba(255, 255, 255, .7);
    cursor: pointer;*/
}

input[type="button"].primary:hover,
input[type="submit"].primary:hover,
.quickNav1:hover,
input[type="button"].secondary:hover,
input[type="submit"].secondary:hover,
.btnGrid:hover,
a.primary:hover,
a.secondary:hover {
    /* background-image: none;
    -moz-box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 1px 0px rgba(255,255,255,.5);
    -webkit-box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 1px 0px rgba(255,255,255,.5);
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 1px 0px rgba(255,255,255,.5);
*/
}

.primary.btn,
input[type="button"].primary,
input[type="submit"].primary {
    color: white;
    border: 1px solid #1F3D5C;
    background-color: #304A64;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#3F6483), to(#2E6499));
    background-image: -webkit-linear-gradient(top, #3F6483, #304A64);
    background-image: -moz-linear-gradient(top, #3F6483, #304A64);
    background-image: -ms-linear-gradient(top, #3F6483, #304A64);
    background-image: -o-linear-gradient(top, #3F6483, #304A64);
    background-image: linear-gradient(top, #3F6483, #304A64);
    text-shadow: 0px -1px 0px black;
}

.searchBbutton a {
    display: block;
    height: 15px;
    width: 61px;
    padding: 4px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 3px;
}


.gridUpperDiv {
    background: #eee;
    padding: 4px;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #ddd;
    overflow: hidden;
    margin-bottom: -3px;
}

.down_arrow {
    display: block;
    padding: 11px 10px;
    background: url('../images/down-left-arrow.png') no-repeat left;
    float: left;
    margin: 4px 5px 0px 2px;
}

.SearchTable label {
    line-height: 12px;
    cursor: default;
    float: right;
}

.PermitSearch label {
    padding-right: 3px;
}

.signup .posRelative {
    line-height: 40px;
}

.signup .posRelative label {
    width: 120px;
    display: inline-block;
    text-align: right;
}

.signup input[type="text"],
input[type="password"],
.signup textarea,
.signup select,
input[type="tel"],
input[type="email"] {
    background-color: rgb(252, 253, 253);
    border-width: 1px;
    border-style: solid;
    -webkit-box-shadow: 0 1px #e5e5e5 inset;
    -moz-box-shadow: 0 1px #e5e5e5 inset;
    box-shadow: 0 1px #e5e5e5 inset;
    -o-border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    /* width: 250px; */
}

.flsSignupForm {
    width: 100%;
    margin: 0 auto;
}

.inputDropDown {
    width: 258px !important;
}

.button {
    padding-left: 10px;
    padding-right: 10px;
}

table.dataTable tbody tr {
    height: 100px;

}

.dataTables_length {
    margin-bottom: 15px;
    padding-top: 20px;
}

table.dataTable tbody tr:nth-child(even) {
    background-color: #CCC !important;



}

table.dataTable tbody tr:nth-child(odd) {
    background-color: #FFF !important
}


table.dataTable tbody td {
    border: 1px solid black;
}

#rolesDiv {
    height: 170px;
    margin-left: 12px;
    overflow-y: scroll;
    width: 250px;
}

#rolesTable {
    line-height: 10px;
    text-align: center;
}

.SearchTable tr td {
    padding-left: 10px;
    padding-bottom: 10px;
}

.SearchTable tr td input[type="text"],
.SearchTable tr td select {
    width: 200px;
}