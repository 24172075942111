@import url(//fonts.googleapis.com/css?family=Open+Sans:300,400,700);

body {
    /* background-color: #E1E1E1; */
    font-family: 13px/1.55 "Open Sans", Helvetica, Arial, sans-serif;
}

/*.body-s
{
    max-width: 400px;
    position: relative;
    z-index: 9999;
    margin:0 auto;
    padding:40px;
}*/


.footer-staff {
    background-color: #074474;
    border-top: 1px solid #939393;
    color: #ffffff;
    font-size: 14px;
    min-height: 410px;
    height: 100%;
    position: absolute;
    width: 100%;
}

.staff-login {
    background-color: #f1f1f1;
}

/*backoffice login css*/

.back-office-form {
    width: 550px;
    margin: 0 auto;
}

.staff-container {
    background: #fff;
    display: inline-block;
    position: relative;
    max-width: 750px;
    width: 100%;
    text-align: left;
    margin-top: 130px;
    margin-bottom: 55px;
    border: 1px solid #d8d8d8;
    box-shadow: 0 0 8px #dedede;
    border-radius: 4px;
}

.staff-logo-circle {
    margin-top: -114px !important;
    background-color: white;
    box-shadow: 0px -12px 9px #efeded;
    border-radius: 50%;
    width: 41% !important;
}

.pt10 {
    padding-top: 10px;
}

.online-header-board {
    font-family: tahoma;
    font-size: 31px;
    font-weight: 600;
    text-align: center;
    color: white;
    letter-spacing: 5px;
    /* text-shadow: 0px 5px 5px #0366b2; */
    padding-top: 0px;
}

.online-header-board span h1 {
    font-family: tahoma;
    font-size: 31px;
    font-weight: 600;
    text-align: center;
    color: white;
    letter-spacing: 5px;
    /* text-shadow: 0px 5px 5px #0366b2; */
    padding-top: 0px;
    margin: 0px;
}

.back-office-form .staff-logo-circle {
    margin: 0 auto 0px auto;
}

.info-backoffice {
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.loginstaff {
    width: 86%;
    margin: auto;
}

.loginstaff tr td {
    position: relative;
    padding-bottom: 15px;
}

.site-logostaff input {
    border: 1px solid #ddd !important;
    width: 100%;
    color: #5D5555;
    box-shadow: none;
    font-size: 14px;
    background: #FBFAF4;
    border: 2px solid;
    height: 34px;
    padding: 0 10px;
    margin: 0 7px 0 0;
    line-height: 34px;
    /* outline: 0; */
    border-radius: 4px;
    vertical-align: middle;
}

.staff-logo-circle span {
    position: absolute;
    width: 225px;
    height: 202px;
    background-repeat: no-repeat;
    background-size: 95%;
    margin-left: 2px;
    background-position: center;
    margin-top: 3px;
    border-radius: 50%;
}

@media screen and (max-width: 600px) {

    .boardname-online,
    h2.boardname-online {
        margin: 0px 0px 0 auto !important;
    }

    .img-set img {
        padding-top: 0 !important;
    }
}

.boardname-online,
h2.boardname-online {
    font-family: tahoma;
    font-size: 42px;
    line-height: 60px;
    letter-spacing: 2px;
    color: #0B5D9A;
    margin: 40px 13px 0 auto;
    text-align: center;
    text-shadow: 1px 0px 1px #0B6DAF;
}

.img-set img {
    padding-top: 10%;
}

.footer-design {
    font-size: 13px;
    font-family: Helvetica, sans-serif;
    letter-spacing: 1.2px;
    font-weight: normal;
}

.headerlogin img {
    padding-top: 2%;
}

.circleset {
    margin: 0 auto 0px auto;
    height: 225px;
}

.staff-login-css {
    float: right;
}

.form-control:focus {
    box-shadow: none;
    border: 1px solid #074474 !important;
}

.requirederrormessage {
    float: right;
    position: absolute;
    text-align: left;
    border-radius: 3px;
    border: 1px solid #ffbfbf;
    margin-right: 0px;
    background-color: #ffffff;
}

.email-msg .nat-err-msg {
    right: 0px;
}

/*end*/

.note {
    color: #999999;
    font-size: 11px;
    line-height: 15px;
    margin-top: 6px;
    padding: 0 1px;
    /* color: #074474; */
    color: #0D5996;
}

.login-form-section {
    margin: 25px auto;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.19);
    color: #666666;
    /* outline: medium none; */
    position: relative;
    z-index: 1;
}

.login-content {
    background-color: #ffffff;
}

.section-title {
    background: rgba(248, 248, 248, 0.9) none repeat scroll 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #232323;
    display: block;
    font-size: 25px;
    font-weight: 300;
    padding: 27px 30px;
    font-family: OpenSans-Light;
}

.section-title h3,
.section-title h2 {
    margin-bottom: 0;
    margin-top: 0;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 24px;
}


.log-error {
    font-size: 16px;
    margin-bottom: 15px;
}

.log-error ul li {
    list-style: inside;
}

.box-wrap {
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    border: medium none;
    display: block;
    padding: 40px 30px 22px;
}

.log-input input {
    -moz-appearance: none;
    background: #ffffff none repeat scroll 0 0;
    border-radius: 0;
    border-style: solid;
    border-color: #e5e5e5;
    border-width: 1px;
    box-sizing: border-box;
    color: #404040;
    display: block;
    font-size: 15px;
    font-family: Open sans;
    height: 39px;
    outline: medium none;
    padding: 8px 10px !important;
    width: 100%;
}

.forget-pass {
    color: #999999;
    font-family: Open sans;
    font-size: 12px;
    line-height: 15px;
    margin-top: -15px;
    padding: 0 1px;
    cursor: pointer;
    margin-bottom: 15px;
}

.filled-in {
    margin-left: -7px;
    z-index: 1000;
}

.no-shadow {
    box-shadow: none !important;
}

.filled-in label {
    font-size: 15px;
    line-height: 26px !important;
    margin-left: 5px;
    vertical-align: top;
    color: #404040;
    font-family: Open sans;
    padding-left: 27px;
    font-weight: inherit;
}

input:checked+i::after,
.checkbox input:checked+i::after {
    opacity: 1;
}

.checkbox input+i::after {
    color: #074474;
}

.checkbox input+i::after {
    content: "";
    font: 12px/16px FontAwesome;
    height: 15px;
    left: -1px;
    text-align: center;
    top: -2px;
    width: 15px;
    vertical-align: top;
}

input+i::after,
.checkbox input+i::after {
    opacity: 0;
    position: absolute;
    transition: opacity 0.1s ease 0s;
}

input:checked+i,
.checkbox input:checked+i,
.toggle input:checked+i {
    border-color: #074474;
}

.checkbox i,
.toggle i,
.icon-append,
.icon-prepend {
    border-color: #e5e5e5;
    transition: border-color 0.3s ease 0s;
}

.checkbox:hover i {
    border-color: #64bfbe;
}

.checkbox i {
    background: #ffffff none repeat scroll 0 0;
    border-style: solid;
    border-width: 2px;
    display: block;
    height: 16px;
    left: 0;
    outline: medium none;
    position: absolute;
    top: 5px;
    width: 16px;
}

.checkbox input+i::after {
    color: #074474;
}

.log-btn {
    background: #f3f3f3 none repeat scroll 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    padding: 24px 30px 8px;
    overflow: hidden;
    font-family: Verdana;
}

.log-btn .button {
    background-color: #074474;
    border: 1px solid rgba(74, 93, 200, 0.8);
    border-radius: 4px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
    color: #ffffff;
    margin-bottom: 10px;
    font-family: verdana;
    font-size: 15px;
    height: 39px;
    margin: 0 0 14px;
    /* outline: medium none; */
    overflow: hidden;
    padding: 0 25px;
    text-decoration: none;
    font-weight: normal;
}

.button-login {
    background-color: #ffffff !important;
    border: 1px solid #dedede !important;
    color: #444444 !important;
}

/*------------------ Login woemail -------------------*/

.loginwo-logo-span span {
    float: left;
    font-family: tahoma;
    font-size: 30px;
    color: #0B5D9A;
    margin: 40px 13px 0 auto;
    text-align: center;
    width: 500px;
    text-shadow: 1px 0px 1px #0B6DAF;
}

.fst-logo {
    float: left;
    margin-top: 41px;
    text-align: right;
}

.text-normal {
    font-weight: normal;
}

.listcss {
    height: 70px;
    background-color: white;
    width: 100%;
    border: 1px solid rgb(222, 222, 222);
    padding-left: 10px;
}

.listcss input {
    width: auto !important;
    height: auto !important;
    margin-top: 6px;
    float: left;
}

.listcss label {
    float: left;
    margin-left: 5px;
    font-weight: normal;
    padding-top: 5px;
}

.listcss tr {
    float: left;
    width: 100%;
    padding-left: 15px;
}

.filled-in-register label {
    float: left;
    font-weight: normal;
    line-height: 20px;
    padding-left: 19px;
}

.filled-in-register {
    margin-bottom: 10px;
}

.bg-white {
    background-color: white !important;
}

.box-shado {
    box-shadow: none !important;
}

.footer {
    background-color: #074474;
    border-top: 1px solid #939393;
    color: #ffffff;
    /* color: black; */
    font-size: 14px;
    position: absolute;
    width: 100%;
    padding-bottom: 40px;
    margin-top: -64px;
}

.footer .txtfooter {
    padding-top: 27%;
}

.footer a {
    color: #fff;
    /* color: black; */
}

.staff-login-user-view {
    padding: 135px 0 97px;
}

.staff-login-user-view .circleset {
    height: 170px;
}

/* login page banner styles start here */
.loginpage {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;

    .loginpage-child-left {
        flex: 99 0 400px;
        padding: 10px 20px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.19);
        background: #f3f3f3 none repeat scroll 0 0;
        z-index: 1;
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .loginpage-child-right {
        flex: 1 0 500px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.19);
        background: #f3f3f3 none repeat scroll 0 0;
        z-index: 1;
        margin-bottom: 10px;
    }
}

.footer .txtloginfooter {
    padding-top: 80px;
}

/* login page banner styles end here */