.multi-select {
    width: 100%;
    height: 100%;
    background-color: white;
}

.multi-select .select-caret {
    position: absolute;
    right: 3px;
    top: 50%;
    margin-top: -8px;
    transform: scale(1.4); 
}

.multi-select .dropdown-open {
    /* outline: 1px solid rgb(77, 144, 254); */
    box-shadow: 0px 0px 5px #4D90FE;
}

.multi-select > .multi-select-dropdown-toggle {
    position: relative;
    height: auto;
    border: 1px solid lightgray;
    line-height: 18px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: 100%;
    padding-left: 6px;
    font-size: 14px;
    padding-right: 10px;
}

/* .multi-select > .multi-select-dropdown-toggle {
    text-align: left;
    width: 100%;
    padding-left: 6px;
    font-size: 14px;
    padding-right: 10px;
} */

.multi-select-dropdown-menu .dropdown-item > button {
    height: 27px;
    width: 100%;
}

.multi-select-dropdown-menu {
    text-align: left;
    padding: 10px;
    font-size: 14px;

    max-height: 200px;
    overflow-y: auto;
}