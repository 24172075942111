/*-------------------------- Header Applicant --------------*/

.headerlogin {
    background-color: #074474;
    min-height: 90px;
}

@media (max-width: 768px) {
    .headerlogin {
        background-color: #074474;
        min-height: 140px;
    }

    .headerlogin .col-sm-2 {
        text-align: center;
    }
}

#site_name {
    color: #fff9f4;
    float: left;
    font-size: 53px;
    font-weight: bold;
    margin-left: 3%;
    margin-top: 10px;
}

.box-logo {
    float: left;
    width: 100%;

}

.licensee-box-logo {
    border-radius: 3px;
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);*/
    margin-left: 0px;
    position: relative;
}

.header-middle-logo span {
    float: left;
    font-family: tahoma;
    font-size: 27px;
    font-weight: 600;
    margin: 18px 13px 0 auto;
    text-align: center;
    width: 580px;
}

.header-middle-logo {
    padding-left: 15% !important;
}

.header-fst-logo {
    float: left;
    position: absolute;
}

.headerlicensee-middle-logo {
    float: left;
}

.headerlicensee-middle-logo span {
    float: left;
    font-family: Tahoma Regular, sans-serif;
    font-size: 28px;
    text-align: center;
    color: #fff;
    margin: 23px 13px 0 0px;
    letter-spacing: 2px;
    font-weight: 600;
    text-decoration: none;
}

.headerlicensee-sec-logo {
    float: left;
}

.headerlicensee-sec-logo img {
    width: 45%;
    padding: 5px;
    margin-top: 8px;
}

.headerlicensee-fst-logo {
    float: left;
}

.header-middle-logo {
    float: left;
    margin: 0 132px;
}

.header-sec-logo {
    float: right;
    margin-top: 5px;
}

.appli-box-logo {
    height: 64px;
    background-image: url("../images/logo_2.png");
    background-repeat: no-repeat;
    float: left;
    width: 20%;
    position: relative;
    top: 15px;
    margin-left: 15px;
    background-color: #fefcfc;
    border-radius: 3px;
    background-size: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.box-logo-right {
    background-color: #fefcfc;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    float: right;
    height: 64px;
    position: relative;
    right: 1px;
    top: 13px;
}

#footer {
    background-attachment: scroll;
    background-color: #074474;
    background-position: center top;
    background-repeat: repeat-x;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 1px;
}

#copyright {
    color: #ffffff;
    font-size: 12px;
    padding: 15px 0;
    font-weight: normal;
}

#footer_bottom a,
#footer_bottom a:visited {
    color: #ffffff;
}